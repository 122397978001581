import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { setNotification } from "../../components/notification/ToastNotification";
import { message } from "../../utilities/locals/messages";
import { IPatientListData, IPatientSingleData } from "../../models/patientListInterfaces";
import { addPatientData, addPatientImageInput, editPatientData, fetchPatientList, fetchPatientListSearch, patientUserApprove, removePatientImageInput, resentVerificationMail, updatePatientData, updatePatientUserStatus } from "../../services/patientListServices";
import { EMAIL_ALREADY_EXIST, MOBILE_ALREADY_EXIST } from "../../config/permissions";

export interface PatientListState {
    loading: 'idle' | 'loading' | 'success' | 'fetchSuccessfully' | 'logoUploaded' | 'error';
    patientUserListData: IPatientListData[];
    paginationTotalCount: number;
    patientUser: IPatientSingleData[];
    uploadedImg: string;
    imageId: number | null;
}

const initialState: PatientListState = {
    loading: 'idle',
    patientUserListData: [],
    patientUser: [],
    paginationTotalCount: 0,
    uploadedImg: '',
    imageId: null,
}

export const patientListSlice = createSlice({
    name: "patientListSlice",
    initialState,
    reducers: {
        changePatientLoading: (state, action: PayloadAction<any>) => {
            state.loading = action.payload;
        }
    },
    extraReducers: (builder) => {

        builder.addCase(fetchPatientList.pending, (state) => {
            state.paginationTotalCount = 0;
            state.patientUserListData = [];
            state.loading = 'loading';
        });
        builder.addCase(fetchPatientList.fulfilled, (state, action) => {
            state.paginationTotalCount = action.payload.count;
            state.patientUserListData = action.payload.results as IPatientListData[];
            state.loading = 'fetchSuccessfully';
        });
        builder.addCase(fetchPatientList.rejected, (state) => {
            state.loading = 'error';
        });

        builder.addCase(fetchPatientListSearch.pending, (state) => {
            state.paginationTotalCount = 0;
            state.patientUserListData = [];
            state.loading = 'idle';
        });
        builder.addCase(fetchPatientListSearch.fulfilled, (state, action) => {
            state.paginationTotalCount = action.payload.count;
            state.patientUserListData = action.payload.results as IPatientListData[];
            state.loading = 'fetchSuccessfully';
        });
        builder.addCase(fetchPatientListSearch.rejected, (state) => {
            state.loading = 'error';
        });

        builder.addCase(addPatientData.pending, (state) => {
            state.loading = 'loading';
        });
        builder.addCase(addPatientData.fulfilled, (state) => {
            state.loading = 'success';
            setNotification(message.CFPCareConnect, message.patientAddedSuccessfully, false, 'success', 'addPatientData');

        });
        builder.addCase(addPatientData.rejected, (state, { payload }: any) => {
            state.loading = 'error';

            if (payload.response.data.error_message === EMAIL_ALREADY_EXIST) {
                setNotification(message.CFPCareConnect, message.emailExist, false, 'failed', 'addPatientData');
            } else if (payload.response.data.error_message === MOBILE_ALREADY_EXIST) {
                setNotification(message.CFPCareConnect, message.mobileNumberExist, false, 'failed', 'addPatientData');
            } else {
                setNotification(message.CFPCareConnect, message.userAddedfailed, false, 'failed', 'addPatientData');
            }

        });

        builder.addCase(updatePatientData.pending, (state) => {
            state.loading = 'loading';
        });
        builder.addCase(updatePatientData.fulfilled, (state, action) => {

            const lastName = action.payload.last_name;
            const firstName = action.payload.first_name;
            const fullName = `${(lastName !== "" && lastName !== null) ? lastName : ''}${(firstName !== "" && firstName !== null) ? (lastName !== "" && lastName !== null) ? ', ' + firstName : firstName : ''}`

            state.loading = 'success';
            setNotification(message.CFPCareConnect, message.userUpdatedSuccessfully, true, 'success', 'updatePatientData', fullName);

        });
        builder.addCase(updatePatientData.rejected, (state, { payload }: any) => {
            state.loading = 'error';
            if (payload.response.data.error_message === EMAIL_ALREADY_EXIST) {
                setNotification(message.CFPCareConnect, message.emailExist, false, 'failed', 'updatePatientData');
            } else {
                setNotification(message.CFPCareConnect, message.userUpdatedfailed, false, 'failed', 'updatePatientData');
            }
        });

        builder.addCase(editPatientData.pending, (state) => {
            state.loading = 'loading';
        });
        builder.addCase(editPatientData.fulfilled, (state, action) => {
            state.loading = 'fetchSuccessfully';
            state.patientUser = [action.payload] as IPatientSingleData[];
        });
        builder.addCase(editPatientData.rejected, (state) => {
            state.loading = 'error';
        });

        builder.addCase(updatePatientUserStatus.pending, (state) => {
            state.loading = 'loading';
        });
        builder.addCase(updatePatientUserStatus.fulfilled, (state, action) => {
            state.loading = 'success';
            const m = action.payload.message;
            setNotification(message.CFPCareConnect, m, false, 'success', 'updatePatientUserStatus');
        });
        builder.addCase(updatePatientUserStatus.rejected, (state) => {
            state.loading = 'error';
            setNotification(message.CFPCareConnect, message.updationFailed, false, 'failed', 'updatePatientUserStatus');

        });


        builder.addCase(patientUserApprove.pending, (state) => {
            state.loading = 'loading';
        });
        builder.addCase(patientUserApprove.fulfilled, (state) => {
            state.loading = 'success';
            setNotification(message.CFPCareConnect, message.approvedSuccessfully, false, 'success', 'patientUserApprove');
        });
        builder.addCase(patientUserApprove.rejected, (state) => {
            state.loading = 'error';
            setNotification(message.CFPCareConnect, message.updationFailed, false, 'failed', 'patientUserApprove');

        });

        builder.addCase(resentVerificationMail.pending, (state) => {
            state.loading = 'loading';
        });
        builder.addCase(resentVerificationMail.fulfilled, (state) => {
            state.loading = 'fetchSuccessfully';
            setNotification(message.CFPCareConnect, message.verificationEmailSuccessfully, false, 'success', 'resentVerificationMail');
        });
        builder.addCase(resentVerificationMail.rejected, (state) => {
            state.loading = 'error';
            setNotification(message.CFPCareConnect, message.verificationEmailFailed, false, 'failed', 'resentVerificationMail');

        });

        builder.addCase(addPatientImageInput.pending, (state) => {
            state.uploadedImg = '';
            state.imageId = null;
            state.loading = 'idle';
        });

        builder.addCase(addPatientImageInput.fulfilled, (state, action) => {
            state.uploadedImg = action.payload.profile_pic;
            state.imageId = action.payload.id;
            state.loading = 'logoUploaded';
        });
        builder.addCase(addPatientImageInput.rejected, (state) => {
            state.loading = 'error';
        });

        builder.addCase(removePatientImageInput.pending, (state) => {
            state.loading = 'idle';
        });

        builder.addCase(removePatientImageInput.fulfilled, (state) => {
            state.uploadedImg = '';
            state.imageId = null;
            state.loading = 'logoUploaded';
        });
        builder.addCase(removePatientImageInput.rejected, (state) => {
            state.loading = 'error';
        });



    }
});

export const { changePatientLoading } = patientListSlice.actions;
export const PatientListSlices = (state: RootState) => state.patientListData;
export const patientListReducer = patientListSlice.reducer;