
export const message = {
    CFPCareConnect: "CFP Care Connect",
    InvalidCredentials:'Invalid credentials.',
    fillOutAllMandatoryFields:'Please fill out all of the mandatory fields.',
    clientAddedSuccessfully:"Client admin added successfully.",
    profileUpdateSuccessful:"Profile Update Successful",
    clientUpdatedSuccessfully:"Client admin updated successfully.",
    clientAddedfailed:'Failed to add client admin.',
    clientUpdatedfailed:'Failed to update client admin.',
    updationFailed:"Updation failed.",
    linkExpired:"Link expired.",
    userVerified:"This user is already verified.",
    approvedSuccessfully:"Approved successfully.",
    verificationEmailSuccessfully:"Verification email has been resent.",
    verificationEmailFailed:"Failed to send a verification email.",
    confirmUserApprove:"Are you sure you want to approve this User?",
    passwordMismatches:"The passwords entered do not match the confirmed password.",
    updationSuccessfully:"Updated successfully.",
    invalidUser: "Invalid user",
    msgEmailResentSuccess: "Verification email sent successfully.",
    msgEmailOTP: "Please check your email for a One-Time Password (OTP)",
    invalidOTP: "Invalid OTP",
    facilityAddedSuccessfully:"Facility added successfully.",
    facilityAddedfailed:'Failed to add facility.',
    facilityUpdatedSuccessfully:"Facility updated successfully.",
    facilityUpdatedfailed:'Facility update failed.',
    invalidMobile: "Invalid mobile number.",
    invalidDOB: "Invalid date of birth.",
    associateAddedSuccessfully:"Associate user added successfully.",
    associateAddedfailed:'Failed to add associate user.',
    associateUpdatedSuccessfully:"Associate user updated successfully.",
    associateUpdatedfailed:'Associate user update failed.',
    providerAddedSuccessfully:"Provider added successfully.",
    providerAddedfailed:'Failed to add provider.',
    providerUpdatedSuccessfully:"Provider details updated successfully.",
    providerUpdatedfailed:'Failed to update provider details.',
    nursingStaffAddedSuccessfully:"Facility staff added successfully.",
    nursingStaffAddedfailed:'Failed to add facility staff.',
    nursingStaffUpdatedSuccessfully:"Facility staff updated successfully.",
    nursingStaffUpdatedfailed:'Failed to update facility staff details.',
    patientAddedSuccessfully:"Patient Added Successfully.",
    userAddedSuccessfully:"User added successfully.",
    userAddedfailed:'Failed to add user.',
    userUpdatedSuccessfully:"The profile of <<$$>> user_name <<$$>> has been successfully updated.",
    userUpdatedfailed:'User update failed.',
    messageMarkAsCompleted:'Message marked as complete.',
    messageMarkAsIncompleted:'Message marked as incomplete.',
    messageMarkAsCompletedFailed:'Failed to update message status.',
    messageMarkAsIncompletedFailed:'Failed to update message status.',
    messageSentSuccessfully:"Message sent successfully.",
    messageSentFailed:'Failed to send message.',
    emailExist:'Email already exists.',
    mobileNumberExist:'Mobile number already exists.',
    facilityAlreadyExists:'Facility name already exists.',
    clientAlreadyExists:'Client name already exists.',
    userNotApproved:'The user is not approved.',
    userNotActivate:'The user is not activated.',
    userHasNoEmail:'This user does not have an email ID.',
    atmostOnePatient:'Please select only one patient at a time for processing.',
    atleastOnePatient:'Please ensure selection of at least one patient for processing.',
    invalidFacility:'Invalid Facility.',
    atleastOneFields:'Please fill out at least one of the following fields.',
    atleastOneFacility:'You should select at least one facility.',
}
  