import React, { useEffect, useState } from 'react'
import ButtonType from '../../components/button/ButtonType'
import plusIcon from '../../assets/images/plusIcon.svg'
import searchIcon from '../../assets/images/searchIcon.svg'
import Table from '../../components/table'
import { patientListHeader } from '../../components/table/tableHeaders'
import { Box, Modal } from '@mui/material'
import TextField from '../../components/inputFeild/TextFeild'
import PhoneInputFeild from '../../components/inputFeild/PhoneInputFeild'
import SelectField from '../../components/inputFeild/SelectField'
import { useAppDispatch, useAppSelector } from '../../helpers/hook'
import { loginPageSlices } from '../loginPage/loginPageSlice'
import { CommonSlices } from '../common/commonSlice'
import ImageInput from '../../components/inputFeild/ImageInput'
import { IPatientData, IPatientState, IPatientStatus } from '../../models/patientListInterfaces'
import { addPatientData, addPatientImageInput, editPatientData, fetchPatientList, fetchPatientListSearch, patientUserApprove, removePatientImageInput, resentVerificationMail, updatePatientData, updatePatientUserStatus } from '../../services/patientListServices'
import { setNotification } from '../../components/notification/ToastNotification'
import { message } from '../../utilities/locals/messages'
import ModalHeader from '../../components/modal/ModalHeader'
import ConfirmationMessage from '../../components/notification/ConfirmationMessage'
import { DEFAULT_COUNTRY_US, LOGO_UPLOAD_OTHERS, LOGO_UPLOAD_PROFILE_PIC, PATIENTS, P_ACT_ADD_EDIT, ROLE_PATIENTS_RELATIVES, SELECT_TYPE_PATIENTS, STATUE_ACTIVE, STATUE_ALL, STATUE_INACTIVE, STATUE_NOTAPPROVED, USER_ROLE_CLIENT_ADMIN, USER_ROLE_CLIENT_ASSOCIATE, useHasActionPermission, useHasViewPermission } from '../../config/permissions'
import { IImageInput, IRemoveImage, IResentEmail } from '../../models/commonInterfaces'
import { PatientListSlices } from './patientListSlice'
import { fetchRelationshipData } from '../../services/commonServices'
import Paginations from '../../components/Pagination'
import { changeMessageTab, setPatientListClickedData, setPatientListClickedStatus } from '../dashboard/dashboardSlice'
import { IPatientSearchList } from '../../models/dashboardInterfaces'
import { useNavigate } from 'react-router-dom'
import { ROUTE_DASHBOARD } from '../../config/routeConfig'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: 'none',
    borderRadius: '12px',
    overflow: 'hidden',
    boxShadow: 24,
};

const PatientList = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const todayDate = new Date().toISOString().split('T')[0];
    const { token, unid, client, logedUsername, loginUserRole } = useAppSelector(loginPageSlices);
    const { navFacility, facilityData, relationshipData } = useAppSelector(CommonSlices);
    const { patientUserListData, paginationTotalCount, loading, patientUser, imageId, uploadedImg } = useAppSelector(PatientListSlices);

    //PERMISSION
    const checkViewPermission = useHasViewPermission(ROLE_PATIENTS_RELATIVES);
    const hasResendEmailPermission = (loginUserRole === USER_ROLE_CLIENT_ADMIN || loginUserRole === USER_ROLE_CLIENT_ASSOCIATE);

    //Paginaton
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 25;
    // const indexOfLastItem = currentPage * itemsPerPage;
    // const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    // const currentItems = patientUserListData.slice(indexOfFirstItem, indexOfLastItem);   

    const [firstName, setFirstName] = useState<string>('');
    const [middleName, setMiddleName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [profileImage, setProfileImage] = useState<string>('');
    const [imageProId, setImageProId] = useState<number | null>(null);
    const [gender, setGender] = useState<string>('');
    const [patientGender, setPatientGender] = useState<string>('');
    const [patientDOB, setPatientDOB] = useState<string>('');
    const [facility, setFacility] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [userEmailId, setUserEmailId] = useState<number>();
    const [mobile, setMobile] = useState('');
    const [alternativeMobile, setAlternativeMobile] = useState('');
    const [notes, setNotes] = useState<string>('');
    const [emailNotification, setEmailNotification] = useState<string>('2');
    const [selectType, setSelectType] = useState<string>('1');
    const [relationship, setRelationship] = useState<string>('1');
    const [patientFirstName, setPatientFirstName] = useState<string>('');
    const [patientMiddleName, setPatientMiddleName] = useState<string>('');
    const [patientLastName, setPatientLastName] = useState<string>('');
    const [allergies, setAllergies] = useState<string>('');

    const [sortField, setSortField] = useState("full_name");
    const [openCreatePatient, setOpenCreatePatient] = useState(false);
    const [viewOnly, setviewOnly] = useState<boolean>(false);
    const [userId, setUserId] = useState<string>('');
    const [showStatusConfirmation, setShowStatusConfirmation] = useState<boolean>(false);
    const [storeStatus, setStoreStatus] = useState<boolean>(false);
    const [storeStatusData, setStoreStatusData] = useState<any>();
    const [addPatient, setAddPatient] = useState<boolean>(true);
    const [resendEmail, setresendEmail] = useState<boolean>(false);
    const [showApproveConfirmation, setShowApproveConfirmation] = useState<boolean>(false);
    const [storeApproveData, setStoreApproveData] = useState<any>();

    const [filterStatus, setFilterStatus] = useState<string>("");
    const [searchPatient, setSearchPatient] = useState<string>("");

    //initial fetching the data to display item on table
    useEffect(() => {
        if (checkViewPermission && (navFacility && navFacility.length > 0)) {
            const req: IPatientState = {
                unid, token,
                facility: navFacility,
                client: client,
                user: logedUsername,
                group: loginUserRole,
                user_type: PATIENTS,
                page: currentPage,
                status_filter: Number(filterStatus),
                sort: sortField,
                search: searchPatient
            };
            dispatch(fetchPatientList(req));
        }

    }, [navFacility])

    //use to reload the table data when dispatch oru other functionality is applied
    useEffect(() => {
        if (loading === 'success' && checkViewPermission) {
            const req: IPatientState = {
                unid, token,
                facility: navFacility,
                client: client, user: logedUsername,
                group: loginUserRole,
                user_type: PATIENTS,
                page: currentPage,
                status_filter: Number(filterStatus),
                sort: sortField,
                search: searchPatient
            };
            dispatch(fetchPatientList(req));
            if (openCreatePatient) {
                handlePatientReset();
            }
        }
    }, [loading]);

    //to handle edit patient 
    useEffect(() => {
        if (patientUser.length > 0) {
            const userDetails = patientUser[0]
            setAddPatient(false);
            setFirstName(userDetails.first_name);
            setMiddleName(userDetails.middle_name);
            setLastName(userDetails.last_name);
            setGender(userDetails.gender.toString());
            setPatientGender(userDetails.patient_gender.toString());
            setFacility(userDetails.facility.toString());
            setEmail(userDetails.email);
            setresendEmail(userDetails.email.length>0?userDetails.email_verified:true);
            setMobile(userDetails.mobile);
            setAlternativeMobile(userDetails.alternate_mobile !== null ? userDetails.alternate_mobile : '');
            setProfileImage(userDetails.profile_pic_url !== null ? userDetails.profile_pic_url : '');
            setImageProId(userDetails.profile_pic)
            setNotes(userDetails.notes);
            setEmailNotification(userDetails.notification ? userDetails.notification.toString() : '2');
            setPatientFirstName(userDetails.patient_first_name);
            setPatientMiddleName(userDetails.patient_middle_name);
            setPatientLastName(userDetails.patient_last_name);
            setPatientDOB(userDetails.patient_dob);
            setAllergies(userDetails.allergies);
            setUserEmailId(userDetails.user_id);
            setRelationship(userDetails.relationship.toString())
            setSelectType(userDetails.patient_group === SELECT_TYPE_PATIENTS ? '1' : '2')
        }
    }, [patientUser]);

    useEffect(() => {
        setImageProId(imageId);
        setProfileImage(uploadedImg);
    }, [imageId, uploadedImg])


    //save button function on modal
    const handleSavePatientData = (e: any) => {
        e.preventDefault();

        if (firstName.length > 0 && lastName.length > 0 && facility.length > 0) {
            if (mobile.length < 2 || mobile.length > 6) {
                if (patientDOB < todayDate) {

                    const req: IPatientData = {
                        unid,
                        token,
                        uuid: userId,
                        client: client,
                        type: Number(selectType),
                        facility: Number(facility),
                        gender: Number(gender),
                        profile_pic: imageProId,
                        sign: null,
                        first_name: firstName,
                        middle_name: middleName,
                        last_name: lastName,
                        email: email,
                        mobile: mobile,
                        alternate_mobile: alternativeMobile,
                        notes: notes,
                        notification: Number(emailNotification),
                        patient_first_name: patientFirstName,
                        patient_middle_name: patientMiddleName,
                        patient_last_name: patientLastName,
                        patient_gender: Number(patientGender),
                        patient_dob: patientDOB,
                        allergy: allergies,
                        relationship: Number(relationship),
                    }
                    if (addPatient) {
                        //for add client
                        dispatch(addPatientData(req));
                    } else {
                        //for update client
                        dispatch(updatePatientData(req));
                    }
                } else {
                    setNotification(message.CFPCareConnect, message.invalidDOB, false, 'failed', 'handleSavePatientData');
                }
            } else {
                setNotification(message.CFPCareConnect, message.invalidMobile, false, 'failed', 'handleSavePatientData');
            }
        } else {
            setNotification(message.CFPCareConnect, message.fillOutAllMandatoryFields, false, 'failed', 'handleSavePatientData');
        }
    }



    const handleOpenModal = () => {
        dispatch(fetchRelationshipData({ token, unid }));
        handlePatientReset();
    }

    //edit click on table
    const handleClickEdit = (data: any) => {
        const uuid = data.uuid;
        setUserId(uuid);
        const req: IPatientState = {
            unid, token, uuid
        };
        dispatch(editPatientData(req));
        dispatch(fetchRelationshipData({ token, unid }));
        setOpenCreatePatient(true);
    }

    //name click on table
    const handleNameClick = (data: any) => {

        dispatch(changeMessageTab(2));
        dispatch(setPatientListClickedStatus(true));
        const req: IPatientSearchList = {
            id: data.id,
            name: data.patient_name,
            email: data.email,
            profile_pic: data.profile_pic,
            allergies: data.allergies,
            patient_dob: data.patient_dob,
            patient_gender: data.patient_gender,
            relationship: data.relationship,
            facility_id: data.facility,
            user_id: Number(data.user_id),
            facility_name: data.facility_name,
        };
        dispatch(setPatientListClickedData(req));
        navigate(ROUTE_DASHBOARD);

    }

    //View click on table
    const handleClickView = (data: any) => {
        setviewOnly(true);
        handleClickEdit(data);
    }

    //function handles Deactivate and Activation of client 
    const handleConfiramation = () => {
        const uuid = storeStatusData.uuid
        const req: IPatientStatus = {
            unid, token, uuid, action: storeStatusData.active_status === STATUE_ACTIVE ? 1 : 2
        };
        dispatch(updatePatientUserStatus(req));
        setShowStatusConfirmation(false);
    }

    //confirmation popup 
    const handleClickStatus = (data: any) => {
        setShowStatusConfirmation(true);
        setStoreStatusData(data);
        setStoreStatus(data.active_status === STATUE_ACTIVE)
    }
    //confirmation approve popup 
    const handleClickApprove = (data: any) => {
        setShowApproveConfirmation(true);
        setStoreApproveData(data);
    }
    //Resent email 
    const onResendEmail = (data: any) => {
        const req: IResentEmail = {
            unid, token, user_id: data.user_id, user_email: data.email

        };
        dispatch(resentVerificationMail(req));
    }
    //function handles Approve 
    const handleConfiramationApprove = () => {
        const uuid = storeApproveData.uuid
        const req: IPatientStatus = {
            unid, token, uuid
        };
        dispatch(patientUserApprove(req));
        setShowApproveConfirmation(false);
    }
    //for reset all value in modal
    const handlePatientReset = () => {
        setFirstName('');
        setMiddleName('');
        setLastName('');
        setGender('');
        setFacility('');
        setNotes('');
        setEmailNotification('2');
        setEmail('');
        setMobile('');
        setAlternativeMobile('');
        setProfileImage("");
        setOpenCreatePatient(!openCreatePatient);
        setviewOnly(false);
        setresendEmail(false);
        setAddPatient(true);
        setUserId('');
        setImageProId(null);
        setPatientFirstName('');
        setPatientMiddleName("");
        setPatientLastName("");
        setPatientDOB("");
        setAllergies("");
        setRelationship("1");
        setSelectType("1");
        // setCurrentPage(1);
    }

    //for add profile image
    const handleAddImage = (selectedFile: File) => {
        const req: IImageInput = {
            token, unid, image: selectedFile, type: LOGO_UPLOAD_OTHERS, image_type: LOGO_UPLOAD_PROFILE_PIC
        }
        dispatch(addPatientImageInput(req));
    }
    //remove profile image
    const handleRemoveImage = () => {
        if (imageProId && imageProId !== null) {

            const req: IRemoveImage = {
                token, unid, fid: imageProId
            }
            dispatch(removePatientImageInput(req));
        }
    }

    // pagination
    //function to call patient list
    const handlePatientList = (value: any, field: string) => {
        const req: IPatientState = {
            unid, token,
            facility: navFacility,
            client: client,
            user: logedUsername,
            group: loginUserRole,
            user_type: PATIENTS,
            page: (field === 'pagination') ? value : currentPage,
            status_filter: (field === 'status_filter') ? Number(value) : Number(filterStatus),
            sort: (field === 'sort_patient') ? value : sortField,
            search: (field === 'search_patient') ? value : searchPatient
        };
        if (field === 'search_patient') {
            dispatch(fetchPatientListSearch(req));
        } else {
            dispatch(fetchPatientList(req));
        }
    }

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        if (checkViewPermission) {
            handlePatientList(page, 'pagination');
        }
    };

    return (
        <div className='w-full'>
            <div className="flex justify-start items-center  pt-4 pb-8 pl-12 pr-8">
                <div>
                    <h1 className='text-2xl text-[var(--color-212121)] '>Patients</h1>
                </div>
            </div>
            <div className="flex justify-between items-center   pb-9 pl-12 pr-8">

                <div className="flex items-center gap-[9px] bg-[var(--color-white)] border border-[var(--color-BDBDBD)] rounded px-3 py-[9px]">
                    <img src={searchIcon} alt="search" loading='lazy' decoding='async' />
                    <input type="search" value={searchPatient} onChange={(e) => {
                        setSearchPatient(e.target.value);
                        handlePatientList(e.target.value, 'search_patient');
                    }} name="" id="" className='focus:outline-none border-none text-xs w-52' placeholder='Search Patient' />
                </div>
                <div className="flex gap-4 items-center">
                    <div className="flex items-center gap-3" >
                        <div className=' tracking-[0.05px] text-[var(--color-424242)]'>Status&nbsp;Filter: </div>
                        <div>
                            <SelectField
                                className=' select-message py-[9px] rounded border-[var(--color-BDBDBD)]  min-w-[138px]'
                                value={filterStatus}
                                onChange={(e) => {
                                    setFilterStatus(e.target.value);
                                    handlePatientList(e.target.value, 'status_filter');
                                }}
                                options={[{ name: 'All', id: STATUE_ALL.toString() }, { name: 'Active', id: STATUE_ACTIVE.toString() }, { name: 'Inactive', id: STATUE_INACTIVE.toString() }, { name: 'Not Approved', id: STATUE_NOTAPPROVED.toString() }]}
                            />
                        </div>
                    </div>
                    <div>
                        {useHasActionPermission(ROLE_PATIENTS_RELATIVES, P_ACT_ADD_EDIT) && <ButtonType type='iconLeft' buttonLabel='Add Patient' icon={plusIcon} onClick={() => handleOpenModal()} />}
                    </div>
                </div>
            </div>
            <div className='pl-12 pr-8 '>
                <Table
                    parentPage={ROLE_PATIENTS_RELATIVES}
                    setPermission={true}
                    titles={patientListHeader}
                    data={patientUserListData}
                    currentPage={currentPage}
                    numRowsPerPage={itemsPerPage}
                    hasResendEmailPermission={hasResendEmailPermission}
                    sortedFieldName={sortField}
                    handleSort={(sortField: string) => {
                        setSortField(sortField);
                        handlePatientList(sortField, 'sort_patient');
                    }}
                    onClickNameClick={(uuid: any) => handleNameClick(uuid)}
                    onClickEdit={(uuid: any) => handleClickEdit(uuid)}
                    onClickView={(uuid: any) => handleClickView(uuid)}
                    onClickStatus={(obj: any) => handleClickStatus(obj)}
                    onClickApprove={(obj: any) => handleClickApprove(obj)}
                    onResendEmail={(obj: any) => onResendEmail(obj)}
                    className={Math.ceil(paginationTotalCount / itemsPerPage) > 1 ? '' : ' mb-28 '}

                />
            </div>
            <Paginations
                totalItems={paginationTotalCount}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                setCurrentPage={handlePageChange}
            />

            <Modal
                open={openCreatePatient}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className='focus:outline-none w-full max-w-[936px]'>

                    <ModalHeader title={addPatient ? 'Add Patient/Family Profile' : viewOnly ? 'Patient/Family Profile' : 'Update Patient/Family Profile'} onClick={() => handlePatientReset()} />

                    <form typeof='submit' onSubmit={e => handleSavePatientData(e)} className='max-h-[calc(100vh-200px)] custom-scrollBar'>
                        <div className="px-9">

                            <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                                <SelectField
                                    value={selectType}
                                    onChange={(e) => setSelectType(e.target.value)}
                                    label='Select Type'
                                    placeHolder={false}
                                    disabled={viewOnly}
                                    options={[{ name: 'Patient', id: '1' }, { name: 'Relative', id: '2' }]}
                                />
                                <div className="w-full"> &nbsp;</div>
                            </div>

                            {selectType === "2" ? <>
                                <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                                    <TextField
                                        label=' First Name'
                                        required={true}
                                        value={firstName}
                                        disabled={viewOnly}
                                        onChange={(e) => setFirstName(e.target.value)}
                                        placeholder=' First Name' />
                                    <TextField
                                        label=' Middle Name'
                                        value={middleName}
                                        disabled={viewOnly}
                                        onChange={(e) => setMiddleName(e.target.value)}
                                        placeholder=' Middle Name' />
                                </div>
                                <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                                    <TextField
                                        label=' Last Name'
                                        required={true}
                                        value={lastName}
                                        disabled={viewOnly}
                                        onChange={(e) => setLastName(e.target.value)}
                                        placeholder=' Last Name' />
                                    <SelectField
                                        label='Gender'
                                        placeHolder={true}
                                        value={gender}
                                        disabled={viewOnly}
                                        onChange={(e) => setGender(e.target.value)}
                                        options={[{ name: 'Select Gender', id: '' }, { name: 'Male', id: '1' }, { name: 'Female', id: '2' }, { name: 'Other', id: '3' }]}
                                    />
                                </div>
                                <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                                    <SelectField
                                        label='Select Facility'
                                        placeHolder={true}
                                        required={true}
                                        onChange={(e) => setFacility(e.target.value)}
                                        value={facility}
                                        disabled={viewOnly}
                                        options={[
                                            { name: 'Select Facility', id: '', isChecked: false },
                                            ...facilityData
                                        ]}
                                    />

                                    <div className="w-full"> &nbsp;</div>
                                </div>
                            </> : <>
                                <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                                    <div className="flex flex-col gap-9 w-full">
                                        <TextField
                                            label=' First Name'
                                            required={true}
                                            value={firstName}
                                            disabled={viewOnly}
                                            onChange={(e) => setFirstName(e.target.value)}
                                            placeholder=' First Name' />
                                        <TextField
                                            label=' Middle Name'
                                            value={middleName}
                                            disabled={viewOnly}
                                            onChange={(e) => setMiddleName(e.target.value)}
                                            placeholder=' Middle Name' />
                                    </div>
                                    <ImageInput label='Profile Picture' profileImage={profileImage} handleAddImage={handleAddImage} handleRemoveImage={handleRemoveImage} />

                                </div>
                                <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                                    <TextField
                                        label=' Last Name'
                                        required={true}
                                        value={lastName}
                                        disabled={viewOnly}
                                        onChange={(e) => setLastName(e.target.value)}
                                        placeholder=' Last Name' />
                                    <SelectField
                                        label='Select Gender'
                                        placeHolder={true}
                                        value={gender}
                                        disabled={viewOnly}
                                        onChange={(e) => setGender(e.target.value)}
                                        options={[{ name: 'Select Gender', id: '' }, { name: 'Male', id: '1' }, { name: 'Female', id: '2' }, { name: 'Other', id: '3' }]}
                                    />
                                </div>
                                <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                                    <TextField
                                        label='Patient DOB '
                                        required={true}
                                        max={todayDate}
                                        disabled={viewOnly}
                                        type='date'
                                        value={patientDOB}
                                        onChange={(e) => setPatientDOB(e.target.value)}
                                        placeholder='Patient DOB ' />
                                    <SelectField
                                        label='Select Facility'
                                        placeHolder={true}
                                        required={true}
                                        disabled={viewOnly}
                                        onChange={(e) => setFacility(e.target.value)}
                                        value={facility}
                                        options={[
                                            { name: 'Select Facility', id: '', isChecked: false },
                                            ...facilityData
                                        ]}
                                    />

                                </div>
                            </>}


                            <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                                <div className="w-full flex flex-col gap-1">
                                    <TextField
                                        label='Email'
                                        value={email}
                                        disabled={viewOnly}
                                        type='email'
                                        onChange={(e) => setEmail(e.target.value)}
                                        placeholder='Email' />
                                    {(!addPatient && !resendEmail && hasResendEmailPermission && (email && email?.length > 0)) && <div className='w-full text-right'>
                                        <sup
                                            className="cursor-pointer text-[var(--color-0048D4)] hover:underline"
                                            onClick={() => onResendEmail({ user_id: userEmailId, email: email })}
                                        >
                                            Resend email
                                        </sup>
                                    </div>}
                                </div>
                                <div className="w-full"></div>
                            </div>
                            <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">

                                <PhoneInputFeild
                                    inputProps={{
                                        name: `mobile`,
                                        autoFocus: false,
                                        disabled: viewOnly,
                                    }}
                                    value={mobile}
                                    defaultCountry={DEFAULT_COUNTRY_US}
                                    label='Mobile'
                                    placeholder='Mobile Number'
                                    onChange={phone => setMobile(phone)} />
                                <PhoneInputFeild
                                    inputProps={{
                                        name: `mobile`,
                                        autoFocus: false,
                                        disabled: viewOnly,
                                    }}
                                    value={alternativeMobile}
                                    defaultCountry={DEFAULT_COUNTRY_US}
                                    disabled={viewOnly}
                                    placeholder='Alternative Phone'
                                    label='Alternative Phone'
                                    onChange={phone => setAlternativeMobile(phone)} />

                            </div>

                            {selectType === "2" &&
                                <>
                                    <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                                        <SelectField
                                            onChange={(e) => setRelationship(e.target.value)}
                                            value={relationship}
                                            disabled={viewOnly}
                                            label='Relationship to the patient'
                                            required={true}
                                            placeHolder={true}
                                            options={[
                                                { name: 'Select Relationship', id: '' },
                                                ...relationshipData
                                            ]}
                                        />
                                        <div className="w-full"></div>
                                    </div>
                                    <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                                        <div className="flex flex-col gap-9 w-full">
                                            <TextField
                                                label=' Patient First Name '
                                                value={patientFirstName}
                                                disabled={viewOnly}
                                                onChange={(e) => setPatientFirstName(e.target.value)}
                                                required={selectType === "2"}
                                                placeholder=' Patient First Name ' />
                                            <TextField
                                                label=' Patient Middle Name'
                                                value={patientMiddleName}
                                                disabled={viewOnly}
                                                onChange={(e) => setPatientMiddleName(e.target.value)}
                                                placeholder=' Patient Middle Name' />
                                        </div>
                                        <ImageInput label='Profile Picture' profileImage={profileImage} handleAddImage={handleAddImage} handleRemoveImage={handleRemoveImage} />

                                    </div>

                                    <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                                        <TextField
                                            label='  Patient Last Name '
                                            value={patientLastName}
                                            disabled={viewOnly}
                                            onChange={(e) => setPatientLastName(e.target.value)}
                                            required={selectType === "2"}
                                            placeholder='  Patient Last Name ' />
                                        <SelectField
                                            onChange={(e) => setPatientGender(e.target.value)}
                                            value={patientGender}
                                            disabled={viewOnly}
                                            label='Patient Gender'
                                            placeHolder={true}
                                            options={[{ name: 'Select Gender', id: '' }, { name: 'Male', id: '1' }, { name: 'Female', id: '2' }, { name: 'Other', id: '3' }]}
                                        />
                                    </div>


                                    <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                                        <TextField
                                            label='Patient DOB '
                                            required={true}
                                            max={todayDate}
                                            type='date'
                                            value={patientDOB}
                                            disabled={viewOnly}
                                            onChange={(e) => setPatientDOB(e.target.value)}
                                            placeholder='Patient DOB ' />
                                        <div className="w-full"></div>
                                    </div>
                                </>
                            }

                            <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                                <label className='flex flex-col gap-1 w-full'>

                                    <span className=' text-[15px] text-[var(--color-212121)] tracking-[0.05px]'>
                                        Notes
                                    </span>
                                    <textarea value={notes} disabled={viewOnly} onChange={e => setNotes(e.target.value)} name="" id="" cols={30} rows={3} className='common-input focus:outline-none   resize-none' />
                                </label>
                                <label className='flex flex-col gap-1 w-full'>

                                    <span className=' text-[15px] text-[var(--color-212121)] tracking-[0.05px]'>
                                        Allergies
                                    </span>
                                    <textarea value={allergies} disabled={viewOnly} onChange={e => setAllergies(e.target.value)} name="" id="" cols={30} rows={3} className='common-input focus:outline-none  resize-none' placeholder='Milk,Peanut,Meat,...' />
                                </label>
                            </div>


                            {(email && email?.length > 0) &&
                             <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                                <div className="w-full">
                                    <div className="flex items-center justify-between mb-2">
                                        <span className=' text-[15px] text-[var(--color-212121)] tracking-[0.05px] w-[141px]'>
                                            Email Notifications
                                        </span>
                                        <div className="flex gap-4">
                                            <input type="radio" id="Yes" name="email_notification" value="1" disabled={viewOnly} checked={emailNotification === '1'} onClick={() => setEmailNotification('1')} /><label htmlFor="Yes">Yes</label></div>
                                        <div className="flex gap-4">
                                            <input type="radio" id="No" name="email_notification" value="2" disabled={viewOnly} checked={emailNotification === '2'} onClick={() => setEmailNotification('2')} /><label htmlFor="No">No</label></div>

                                    </div>

                                </div>
                                <div className="w-full"> &nbsp;</div>

                            </div>
                            }

                            {!viewOnly && <>
                                <hr className='border-[var(--color-E0E0E0)] mt-9' />
                                <div className="flex gap-4 justify-end items-end my-9">
                                    <ButtonType
                                        type='webCardbtn'
                                        buttonLabel='Cancel'
                                        onClick={() => handlePatientReset()}
                                        className='px-12 !important bg-[var(--color-white)] !important imp-text-424242  hover:bg-[var(--color-0000000a)]' />
                                    <ButtonType
                                        type='webCardbtn'
                                        buttonLabel='Save'
                                        buttonType='submit'
                                        // onClick={() => setAlertOpen(true)}
                                        className='px-12 bg-[var(--color-0048D4)]' />
                                </div></>}
                        </div>
                    </form>
                </Box>
            </Modal>
            <ConfirmationMessage
                title={message.CFPCareConnect}
                description={`Are you sure you want to ${storeStatus ? 'deactivate' : 'activate'} this Patient?`}
                open={showStatusConfirmation}
                textType={false}
                type={storeStatus ? 'danger' : 'success'}
                buttonTwoOnClick={() => setShowStatusConfirmation(false)}
                buttonOneOnClick={() => handleConfiramation()}
                buttonLabelOne={storeStatus ? 'Deactivate' : 'Activate'}
                buttonLabelTwo='Cancel'
            />
            <ConfirmationMessage
                title={message.CFPCareConnect}
                description={message.confirmUserApprove}
                open={showApproveConfirmation}
                textType={false}
                type={'warning'}
                buttonTwoOnClick={() => setShowApproveConfirmation(false)}
                buttonOneOnClick={() => handleConfiramationApprove()}
                buttonLabelOne={'Approve'}
                buttonLabelTwo='Cancel'
            />
        </div>
    )
}

export default PatientList