import React, { useEffect, useState } from 'react'
import RegistrationNavbar from '../../components/navbar/RegistrationNavbar'
import { useNavigate } from 'react-router-dom'
import TextField from '../../components/inputFeild/TextFeild'
import ButtonType from '../../components/button/ButtonType'
import RegistrationAside from '../../components/sidebar/RegistrationAside'
import RegistrationSuccess from '../../components/notification/RegistrationSuccess'
import PhoneInputFeild from '../../components/inputFeild/PhoneInputFeild'
import ImageInput from '../../components/inputFeild/ImageInput'
import SelectField from '../../components/inputFeild/SelectField'
import { DEFAULT_COUNTRY_US, LOGO_UPLOAD_OTHERS, LOGO_UPLOAD_PROFILE_PIC, RELATIVES } from '../../config/permissions'
import { useAppDispatch, useAppSelector } from '../../helpers/hook'
import { CommonSlices } from '../common/commonSlice'
import { fetchFacilityDropdowns, fetchRelationshipDatas } from '../../services/commonServices'
import {  ROUTE_LOGIN_PATIENT } from '../../config/routeConfig'
import { FamilyRegiserationSlices, resetImage } from './familyMemberRegistrationSlice'
import { IImageInputWithoutToken, IRemoveImageWithoutToken } from '../../models/commonInterfaces'
import { addFamilyReg, addFamilyRegImage, removeFamilyRegImage } from '../../services/familyRegServices'
import { IFamilyRegData } from '../../models/FamilyRegInterface'
import { setNotification } from '../../components/notification/ToastNotification'
import { message } from '../../utilities/locals/messages'
import ReCAPTCHA from 'react-google-recaptcha'
import { REACT_APP_RECAPTCHA } from '../../config/urlConfig'
import WebFooter from '../../components/footer/WebFooter'

interface FormState {
    firstName: string;
    middleName: string;
    lastName: string;
    gender: string;
    facility: string;
    isAlertOpen: boolean;
    mobile: string;
    email: string;
    alternativeMobile: string;
    relationship: string;
    patientFirstName: string;
    patientMiddleName: string;
    patientLastName: string;
    profileImage: string;
    imageId: number | null;
    patientGender: string;
    patientDOB: string;
    termsAndC: boolean;
    emailNotification: string;
}

const FamilyMemberRegistration = () => {
    const navigate = useNavigate();
    const todayDate = new Date().toISOString().split('T')[0];
    const dispatch = useAppDispatch();
    const recaptcha: string | undefined = REACT_APP_RECAPTCHA;
    const { facilityDatas, relationshipDatas } = useAppSelector(CommonSlices);
    const { loading, imageId, uploadedImg } = useAppSelector(FamilyRegiserationSlices);

    const initialFormState: FormState = {
        firstName: '',
        middleName: '',
        lastName: '',
        gender: '',
        facility: '',
        isAlertOpen: false,
        mobile: '',
        email: '',
        alternativeMobile: '',
        relationship: '',
        patientFirstName: '',
        patientMiddleName: '',
        patientLastName: '',
        profileImage: '',
        patientGender: '',
        patientDOB: '',
        termsAndC: false,
        imageId: null,
        emailNotification: '2',

    };

    const [formState, setFormState] = useState<FormState>(initialFormState);
    const [cathpcha, setcathpcha] = useState<boolean>(false);

    useEffect(() => {
        dispatch(fetchFacilityDropdowns());
        dispatch(fetchRelationshipDatas());
        handleResetData();
    }, [])


    useEffect(() => {
        if (loading === 'success') {
            setFormState({
                ...formState,
                isAlertOpen: true,
            });
        }
    }, [loading])

    useEffect(() => {
        setFormState({
            ...formState,
            imageId: imageId,
            profileImage: uploadedImg,
        });
    }, [imageId, uploadedImg])



    const handleFamilyRegistration = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (formState.firstName.length > 0 && formState.lastName.length > 0 && formState.facility.length > 0) {
            if (formState.mobile.length < 2 || formState.mobile.length > 6) {
                if (formState.patientDOB < todayDate) {

                    const req: IFamilyRegData = {
                        type: 2,
                        facility: Number(formState.facility),
                        first_name: formState.firstName,
                        middle_name: formState.middleName,
                        last_name: formState.lastName,
                        email: formState.email,
                        gender: Number(formState.gender),
                        mobile: formState.mobile,
                        alternate_mobile: formState.alternativeMobile,
                        notes: "",
                        notification: Number(formState.emailNotification),
                        patient_dob: formState.patientDOB,
                        relationship: Number(formState.relationship),
                        patient_first_name: formState.patientFirstName,
                        patient_gender: Number(formState.patientGender),
                        patient_last_name: formState.patientLastName,
                        patient_middle_name: formState.patientMiddleName,
                        profile_pic: formState.imageId,
                        sign: null,
                        allergy: "",
                    }
                    //for add client
                    dispatch(addFamilyReg(req));
                } else {
                    setNotification(message.CFPCareConnect, message.invalidDOB, false, 'failed', 'handleSavePatientData');
                }
            } else {
                setNotification(message.CFPCareConnect, message.invalidMobile, false, 'failed', 'handleSaveFamilyData');
            }
        } else {
            setNotification(message.CFPCareConnect, message.fillOutAllMandatoryFields, false, 'failed', 'handleSaveFamilyData');
        }
    };

    //for add profile image
    const handleAddImage = (selectedFile: File) => {
        const req: IImageInputWithoutToken = {
            image: selectedFile, type: LOGO_UPLOAD_OTHERS, image_type: LOGO_UPLOAD_PROFILE_PIC
        }
        dispatch(addFamilyRegImage(req));
    }

    //remove profile image
    const handleRemoveImage = () => {
        if (formState.imageId && formState.imageId !== null) {

            const req: IRemoveImageWithoutToken = {
                fid: formState.imageId
            }
            dispatch(removeFamilyRegImage(req));
        }
    }
    //reset data
    const handleResetData = () => {
        setFormState({
            firstName: '',
            middleName: '',
            lastName: '',
            gender: '',
            facility: '',
            isAlertOpen: false,
            mobile: '',
            email: '',
            alternativeMobile: '',
            relationship: '',
            patientFirstName: '',
            patientMiddleName: '',
            patientLastName: '',
            profileImage: '',
            patientGender: '',
            patientDOB: '',
            termsAndC: false,
            imageId: null,
            emailNotification: '2',
        });
        dispatch(resetImage());
    }

    function onChangeCapthcha(value: any) {
        if (value !== null && value.length > 0) {
            setcathpcha(true)
        }
    }
    return (
        <>
            <RegistrationNavbar onClick={() => { handleResetData(); navigate('/') }} />
            <section className='flex flex-col justify-center items-center w-full px-[37px] lg:px-[62px] '>
                <div className='mt-[154px] flex w-full max-w-[1436px]  gap-8'>
                    <RegistrationAside parent='family' />
                    <form className='w-full ' onSubmit={(e) => handleFamilyRegistration(e)}>
                        <h2 className='text-xl font-medium text-[var(--color-424242)] tracking-[.05px]'>Personal Information</h2>
                        <hr className='border-[var(--color-E0E0E0)] mt-1' />
                        <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                            <TextField
                                label=' First Name'
                                required={true}
                                value={formState.firstName}
                                onChange={(e) => setFormState({ ...formState, firstName: e.target.value })}
                                placeholder=' First Name' />
                            <TextField
                                label=' Middle Name'
                                value={formState.middleName}
                                onChange={(e) => setFormState({ ...formState, middleName: e.target.value })}
                                placeholder=' Middle Name' />
                        </div>
                        <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                            <TextField
                                label=' Last Name'
                                required={true}
                                value={formState.lastName}
                                onChange={(e) => setFormState({ ...formState, lastName: e.target.value })}
                                placeholder=' Last Name' />
                            <SelectField
                                label='Gender'
                                placeHolder={true}
                                value={formState.gender}
                                onChange={(e) => setFormState({ ...formState, gender: e.target.value })}
                                options={[{ name: 'Select Gender', id: '' }, { name: 'Male', id: '1' }, { name: 'Female', id: '2' }, { name: 'Other', id: '3' }]}
                            />
                        </div>
                        <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                            <SelectField
                                label='Select Facility'
                                search={true}
                                placeHolder={true}
                                required={true}
                                onChange={(e) => setFormState({ ...formState, facility: e.target.value })}
                                value={formState.facility}
                                options={[
                                    { name: 'Select Facility', id: '', isChecked: false },
                                    ...facilityDatas
                                ]}
                            />
                            <div className='w-full'></div>
                        </div>


                        <h2 className='text-xl font-medium text-[var(--color-424242)] tracking-[.05px] mt-20'>Contact Details</h2>
                        <hr className='border-[var(--color-E0E0E0)] mt-1' />
                        <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                            <TextField
                                label='Email'
                                value={formState.email}
                                type='email'
                                onChange={(e) => setFormState({ ...formState, email: e.target.value })}
                                placeholder='Email' />
                            <div className="w-full"></div>
                        </div>
                        <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">

                            <PhoneInputFeild
                                inputProps={{
                                    name: `mobile`,
                                    autoFocus: false,
                                }}
                                value={formState.mobile}
                                defaultCountry={DEFAULT_COUNTRY_US}
                                label='Mobile'
                                placeholder='Mobile Number'
                                onChange={(phone) => setFormState({ ...formState, mobile: phone })}
                            />
                            <PhoneInputFeild
                                inputProps={{
                                    name: `mobile`,
                                    autoFocus: false,
                                }}
                                value={formState.alternativeMobile}
                                defaultCountry={DEFAULT_COUNTRY_US}
                                placeholder='Alternative Phone'
                                label='Alternative Phone'
                                onChange={phone => setFormState({ ...formState, alternativeMobile: phone })} />

                        </div>

                        <h2 className='text-xl font-medium text-[var(--color-424242)] tracking-[.05px] mt-20'>Relationship & Patient Information</h2>
                        <hr className='border-[var(--color-E0E0E0)] mt-1' />
                        <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                            <div className="flex flex-col gap-9 w-full">
                                <SelectField
                                    onChange={(e) => setFormState({ ...formState, relationship: e.target.value })}
                                    value={formState.relationship}
                                    label='Relationship to the patient'
                                    required={true}
                                    placeHolder={true}
                                    options={[
                                        { name: 'Select Relationship', id: '' },
                                        ...relationshipDatas
                                    ]}
                                />
                                <TextField
                                    label=' Patient First Name '
                                    value={formState.patientFirstName}
                                    onChange={(e) => setFormState({ ...formState, patientFirstName: e.target.value })}
                                    required={true}
                                    placeholder=' Patient First Name ' />
                            </div>
                            <ImageInput label='Patient Profile Picture' profileImage={formState.profileImage} handleAddImage={handleAddImage} handleRemoveImage={handleRemoveImage} />

                        </div>
                        <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">

                            <TextField
                                label=' Patient Middle Name'
                                value={formState.patientMiddleName}
                                onChange={(e) => setFormState({ ...formState, patientMiddleName: e.target.value })}
                                placeholder=' Patient Middle Name' />
                            <TextField
                                label='  Patient Last Name '
                                value={formState.patientLastName}
                                onChange={(e) => setFormState({ ...formState, patientLastName: e.target.value })}
                                required={true}
                                placeholder='  Patient Last Name ' />
                        </div>
                        <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">

                            <SelectField
                                onChange={(e) => setFormState({ ...formState, patientGender: e.target.value })}
                                value={formState.patientGender}
                                label='Patient Gender'
                                placeHolder={true}
                                options={[{ name: 'Select Gender', id: '' }, { name: 'Male', id: '1' }, { name: 'Female', id: '2' }, { name: 'Other', id: '3' }]}
                            />
                            <TextField
                                label='Patient DOB '
                                required={true}
                                max={todayDate}
                                type='date'
                                value={formState.patientDOB}
                                onChange={(e) => setFormState({ ...formState, patientDOB: e.target.value })}
                                placeholder='Patient DOB ' />
                        </div>
                        {/* {(formState.email && formState.email.length > 2) &&
                            <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                                <div className="w-full">
                                    <div className="flex items-center justify-between mb-2">
                                        <span className=' text-[15px] text-[var(--color-212121)] tracking-[0.05px] w-[141px]'>
                                            Email Notifications
                                        </span>
                                        <div className="flex gap-4">
                                            <input type="radio" id="Yes" name="email_notification" value="1" checked={formState.emailNotification === '1'} onClick={() => setFormState({ ...formState, emailNotification: '1' })} /><label htmlFor="Yes">Yes</label></div>
                                        <div className="flex gap-4">
                                            <input type="radio" id="No" name="email_notification" value="2" checked={formState.emailNotification === '2'} onClick={() => setFormState({ ...formState, emailNotification: '2' })} /><label htmlFor="No">No</label></div>

                                    </div>

                                </div>
                                <div className="w-full"> &nbsp;</div>

                            </div>} */}

                        <div className="flex gap-4 mt-9 items-center">
                            <input type="checkbox" name="" id="" checked={formState.termsAndC} onChange={(e) => setFormState({ ...formState, termsAndC: e.target.checked })} />
                            I agree the terms & conditions of the use of this website
                        </div>
                        <hr className='border-[var(--color-E0E0E0)] mt-20' />
                        <div className="flex justify-between items-center ">
                            <ReCAPTCHA
                                sitekey={recaptcha ? recaptcha : ''}
                                onChange={onChangeCapthcha}
                            />
                            <div className="flex gap-4 justify-end items-end mt-9 mb-[52px]">
                                <ButtonType
                                    type='webCardbtn'
                                    buttonLabel='Cancel'
                                    onClick={handleResetData}
                                    className='px-12 !important bg-[var(--color-white)] !important imp-text-424242  hover:bg-[var(--color-0000000a)]' />

                                {(formState.termsAndC && cathpcha) && <ButtonType
                                    type='webCardbtn'
                                    buttonLabel='Next'
                                    buttonType='submit'
                                    className='px-12 !important' />}
                            </div>
                        </div>
                    </form>

                </div >
                <RegistrationSuccess
                    parent={RELATIVES}
                    type="registrationSuccess"
                    buttonLabelOne="Done"
                    open={formState.isAlertOpen}
                    onClickOne={() => { navigate(ROUTE_LOGIN_PATIENT); handleResetData() }}
                    onClose={() => setFormState({ ...formState, isAlertOpen: false })}
                />
            </section >
            <WebFooter />
        </>
    )
}

export default FamilyMemberRegistration