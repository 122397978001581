import { useAppSelector } from "../helpers/hook";
import { loginPageSlices } from "../pages/loginPage/loginPageSlice";

export const DEFAULT_COUNTRY = 1;
export const DEFAULT_COUNTRY_US = 'us';

export const USER_ROLE_SUPERADMIN = 1;
export const USER_ROLE_CLIENT_ADMIN = 2;
export const USER_ROLE_CLIENT_ASSOCIATE = 3;
export const USER_ROLE_PROVIDER = 4;
export const USER_ROLE_NURSING_STAFF = 5;
export const USER_ROLE_PATIENTS = 6;
export const USER_ROLE_RELATIVES = 7;

export const LOGO_UPLOAD_CLIENT = 1;
export const LOGO_UPLOAD_FACILITY = 2;
export const LOGO_UPLOAD_OTHERS = 3;
export const LOGO_UPLOAD_PROFILE_PIC = 1;
export const LOGO_UPLOAD_SIGN = 2;
export const LOGO_UPLOAD_LOGO = 3;

export const MSG_TAB_NO_MESSAGE = 0;
export const MSG_TAB_NEW_MESSAGE = 1;
export const MSG_TAB_SEL_MESSAGE = 2;
export const MSG_TAB_SEND_MESSAGE = 3;

export const STATUE_ALL = 0;
export const STATUE_ACTIVE = 1;
export const STATUE_INACTIVE = 2;
export const STATUE_NOTAPPROVED = 3;

export const MSG_TYPE_INDIVIDUAL = 1;
export const MSG_TYPE_GROUP = 2;
export const MSG_TYPE_GENERAL = 3;

export const GENDER_MALE = 1;
export const GENDER_FEMALE = 2;
export const GENDER_TRANSGENDER = 3;
export const GENDER_NA = 4;

export const MSG_TYPE_MESSAGE = 1;
export const MSG_TYPE_ORDER = 2;

export const MSG_FILTER_ALL = 0;
export const MSG_FILTER_READ = 1;
export const MSG_FILTER_UNREAD = 2;
export const MSG_FILTER_ORDERS = 3;
export const MSG_FILTER_MESSAGE = 4;

export const MSG_CLIENT_ADMIN = 'CLIENT ADMIN'
export const MSG_CLIENT_ASSOCIATE = 'CLIENT ASSOCIATE'
export const MSG_PATIENTS = 'PATIENTS'
export const MSG_RELATIVES = 'RELATIVES'
export const MSG_NURSING_STAFF = "NURSING STAFF";
export const MSG_PROVIDER = "PROVIDER";
export const MSG_GENERAL = "GENERAL";
export const MSG_INDIVIDUAL = "INDIVIDUAL";

export const MSG_ACTION_COMPLETE = "complete";
export const MSG_ACTION_INCOMPLETE = "incomplete";

export const SELECT_TYPE_PATIENTS = 'PATIENTS';
export const SELECT_TYPE_RELATIVES = 'RELATIVES';

export const PROVIDER = 'PROVIDER';
export const NURSING_STAFF = 'NURSING_STAFF';
export const NURSING_STAFF_SPACE = 'NURSING STAFF';
export const GENERAL_PATIENTs = 'GENERAL_PATIENTs';
export const CLIENT_ASSOCIATE = 'CLIENT_ASSOCIATE';
export const CLIENT_ADMIN = 'CLIENT ADMIN'
export const PATIENTS = 'PATIENTS';
export const RELATIVES = 'RELATIVES';
export const GENERAL = 'GENERAL';
export const PATIENTS_RELATIVES = 'PATIENTS_RELATIVES'
export const CLIENT_ADMIN_ASSOCIATE = 'ADMIN_ASSOCIATE'

export const SML_ASSOCIATES = 'Associates';
export const ROLE_PATIENTS_NAME = 'PATIENT/RELATIVE';


export const FACILITY_STAFF_SPACE = 'FACILITY STAFF'


export const LBL_NURSING_STAFF = 'Facility Staff';
export const LBL_PROVIDERS = 'Providers';
export const LBL_PATIENTS = 'Patients';
export const LBL_GENERAL = 'General';
export const LBL_GENERAL_PATIENTS = 'General Patient';
export const LBL_CLIENT_ADMIN = 'Client Admin';
export const LBL_ASSOCIATES = 'Associates';
export const LBL_TIMESHEET = 'Timesheet';
export const LBL_ROLE_PERMISSIONS = "Roles and Permissions";
export const LBL_FACILITIES = "Facilities";

//ERROR CONST
export const EMAIL_ALREADY_EXIST = 'email_already_exists';
export const MOBILE_ALREADY_EXIST = 'mobile_number_already_exists';
export const MOBILE_EXIST = 'mobile_already_exists';
export const NAME_EXIST = 'facility_already_exists';
export const CLIENT_NAME_EXIST = 'client_already_exists';
export const USER_NOT_APPROVED = 'user_not_approved';
export const ATMOST_ONE_PATIENT = 'select_atmost_one_patient';
export const ATLEAST_ONE_PATIENT = 'select_atleast_one_patient';
export const INVALID_USER = 'invalid_user';
export const INVALID_FACILITY = 'invalid_facility';
export const INVALID_EMAIL_ID = 'no_valid_email_id';
export const VERIFIED_USER   = 'verified_user';
export const LINK_TIME_EXPIRED  = 'link_expired';


// // PERMISSIONS FOR ACCESS TO PAGES AND ACTIONS 
// // ****************************************************************************
// // ************************* PAGE ACCESS PERMISSIONS **************************
// // ****************************************************************************
export const ROLE_CLIENT_ASSOCIATE_USER = "client_associate_user";
export const ROLE_NURSING_STAFF = "nursing_staff";
export const ROLE_PATIENTS_RELATIVES = "patients_relatives";
export const ROLE_PROVIDER = "provider";
export const ROLE_FACILITY = "facility";
export const ROLE_REPORTS = "reports";
export const ROLE_ROLE_AND_PERMISSIONS = "roles_permissions";

export const ROLE_COMPLETED_MESSAGES = "completed_messages";
export const ROLE_CREATE_SEND_MESSAGES = "create_send_messages";
export const ROLE_VIEW_INBOX_SENT_MESSAGES = "view_inbox_sent_messages";
export const ROLE_VIEW_GENERAL_MESSAGES = "view_general_messages";
export const ROLE_SEND_GENERAL_MESSAGES = "send_general_messages";
export const ROLE_SEND_ORDER = "place_order";
export const ROLE_VIEW_ORDER = "view_order";

export const P_ACT_ADD_EDIT = 2;
export const P_ACT_DEACTIVATE_OR_ACTIVATE = 3;
export const P_ACT_APPROVE_CREATE = 4;
export const P_ACT_ALLOW = 5;

// // FUNCTION TO CHECK WHETHER THE USER HAVE MENU/PAGE PERMISSION 
export const useHasViewPermission = (role: string) => {
    const { userPermissions } = useAppSelector(loginPageSlices);
    // Check if the role exists in rolePermissions object keys
    if (userPermissions && Object.keys(userPermissions).length > 0) {
        // const hasPermission = userPermissions.hasOwnProperty(role);
        const hasPermission = role in userPermissions;
        return hasPermission;
    }

    return false;
};

// // FUNCTION TO CHECK WHETHER THE USER HAVE ACTION PERMISSION 
export const useHasActionPermission = (role: string, permission: number) => {
    const { userPermissions } = useAppSelector(loginPageSlices);
    const permissions = userPermissions[role];
    // Check if permissions array exists and includes the required permission
    return permissions !== undefined && permissions.includes(permission);
};


export const getRefreshToken = () => localStorage.getItem('refreshToken');





