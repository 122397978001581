import React, { useEffect, useState } from 'react'
import ButtonType from '../../components/button/ButtonType'
import plusIcon from '../../assets/images/plusIcon.svg'
import Table from '../../components/table'
import { Box, Modal } from '@mui/material'
import TextField from '../../components/inputFeild/TextFeild'
import PhoneInputFeild from '../../components/inputFeild/PhoneInputFeild'
import { associateUserListHeader } from '../../components/table/tableHeaders'
import SelectField from '../../components/inputFeild/SelectField'
import MultiSelectField from '../../components/inputFeild/MultiSelectField'
import { useAppDispatch, useAppSelector } from '../../helpers/hook'
import { CommonSlices } from '../common/commonSlice'
import { IAssociateData, IAssociateState, IAssociateStatus } from '../../models/associateListInterfaces'
import { loginPageSlices } from '../loginPage/loginPageSlice'
import { addAssociateData, addAssociateImageInput, editAssociateData, fetchAssociateList, removeAssociateImageInput, resentVerificationAssociateMail, updateAssociateData, updateAssociateUserStatus } from '../../services/associateServices'
import { setNotification } from '../../components/notification/ToastNotification'
import { message } from '../../utilities/locals/messages'
import ImageInput from '../../components/inputFeild/ImageInput'
import { AssociateListSlices } from './associateListSlice'
import ModalHeader from '../../components/modal/ModalHeader'
import ConfirmationMessage from '../../components/notification/ConfirmationMessage'
import { CLIENT_ASSOCIATE, DEFAULT_COUNTRY_US, LOGO_UPLOAD_OTHERS, LOGO_UPLOAD_PROFILE_PIC, P_ACT_ADD_EDIT, ROLE_CLIENT_ASSOCIATE_USER, USER_ROLE_CLIENT_ADMIN, USER_ROLE_CLIENT_ASSOCIATE, useHasActionPermission, useHasViewPermission } from '../../config/permissions'
import { IImageInput, IRemoveImage, IResentEmail } from '../../models/commonInterfaces'
import Paginations from '../../components/Pagination'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: 'none',
    borderRadius: '12px',
    overflow: 'hidden',
    boxShadow: 24,
};

const AssociateUserList = () => {
    const { token, unid, client, logedUsername, loginUserRole } = useAppSelector(loginPageSlices);
    const { navFacility, facilityData } = useAppSelector(CommonSlices);
    const { associateUserListData, paginationTotalCount, loading, associateUser, imageId, uploadedImg } = useAppSelector(AssociateListSlices);
    const dispatch = useAppDispatch();
    //Paginaton
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 25;

    //PERMISSION
    const checkViewPermission = useHasViewPermission(ROLE_CLIENT_ASSOCIATE_USER);
    const hasResendEmailPermission = (loginUserRole === USER_ROLE_CLIENT_ADMIN || loginUserRole === USER_ROLE_CLIENT_ASSOCIATE)

    const [sortField, setSortField] = useState("full_name");
    const [openCreateAssociateUser, setOpenCreateAssociateUser] = useState(false);
    const [viewOnly, setviewOnly] = useState<boolean>(false);

    const [firstName, setFirstName] = useState<string>('');
    const [middleName, setMiddleName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [profileImage, setProfileImage] = useState<string>('');
    const [imageProId, setImageProId] = useState<number | null>(null);
    const [gender, setGender] = useState<string>('');
    const [facility, setFacility] = useState<number[]>([]);
    const [email, setEmail] = useState<string>('');
    const [resendEmail, setresendEmail] = useState<boolean>(false);
    const [userEmailId, setUserEmailId] = useState<number>();
    const [mobile, setMobile] = useState('');
    const [alternativeMobile, setAlternativeMobile] = useState('');
    const [notes, setNotes] = useState<string>('');
    const [emailNotification, setEmailNotification] = useState<string>('2');


    const [userId, setUserId] = useState<string>('');
    const [showStatusConfirmation, setShowStatusConfirmation] = useState<boolean>(false);
    const [storeStatus, setStoreStatus] = useState<boolean>(false);
    const [storeStatusData, setStoreStatusData] = useState<any>();
    const [addAssociate, setAddAssociate] = useState<boolean>(true);


    //initial fetching the data to display item on table
    useEffect(() => {

        if (checkViewPermission && (navFacility && navFacility.length > 0)) {
            const req: IAssociateState = {
                unid, token, facility: navFacility, client: client, user: logedUsername, group: loginUserRole, user_type: CLIENT_ASSOCIATE, page: currentPage
            };
            dispatch(fetchAssociateList(req));
        }

    }, [navFacility])

    //use to reload the table data when dispatch oru other functionality is applied
    useEffect(() => {
        if (loading === 'success' && checkViewPermission) {
            const req: IAssociateState = {
                unid, token, facility: navFacility, client: client, user: logedUsername, group: loginUserRole, user_type: CLIENT_ASSOCIATE, page: currentPage
            };
            dispatch(fetchAssociateList(req));

            if (openCreateAssociateUser) {
                handleAssociateReset();
            }
        }

    }, [loading])

    //to handle edit client 
    useEffect(() => {
        if (associateUser.length > 0) {
            const userDetails = associateUser[0]
            setAddAssociate(false);
            setFirstName(userDetails.first_name);
            setMiddleName(userDetails.middle_name);
            setLastName(userDetails.last_name);
            setGender(userDetails.gender.toString());
            setFacility(userDetails.facility);
            setEmail(userDetails.email);
            setresendEmail(userDetails.email_verified);
            setUserEmailId(userDetails.user_id);
            setMobile(userDetails.mobile);
            setAlternativeMobile(userDetails.alternate_mobile);
            setProfileImage(userDetails.profile_pic_url);
            setImageProId(userDetails.profile_pic)
            setNotes(userDetails.notes);
            setEmailNotification(userDetails.notifications?userDetails.notifications.toString():'2');
        }
    }, [associateUser]);

    useEffect(() => {
        setImageProId(imageId);
        setProfileImage(uploadedImg);
    }, [imageId, uploadedImg])

    //save button function on modal
    const handleSaveAssociateData = (e: any) => {
        e.preventDefault();
        if (firstName.length > 0 && lastName.length > 0 && facility.length > 0 && email.length) {
            if (mobile.length < 2 || mobile.length > 6) {

                const req: IAssociateData = {
                    unid,
                    token,
                    uuid: userId,
                    client: client,
                    facility: facility,
                    gender: Number(gender),
                    profile_pic: imageProId,
                    user_info: {
                        first_name: firstName,
                        middle_name: middleName,
                        last_name: lastName,
                        email: email,
                        mobile: mobile,
                        alternate_mobile: alternativeMobile
                    },
                    notes: notes,
                    sign: null,
                    sign_base: '',
                    notifications: Number(emailNotification)
                }
                if (addAssociate) {
                    //for add client
                    dispatch(addAssociateData(req));
                } else {
                    //for update client
                    dispatch(updateAssociateData(req));
                }
            } else {
                setNotification(message.CFPCareConnect, message.invalidMobile, false, 'failed', 'handleSaveAssociateData');
            }
        } else {
            setNotification(message.CFPCareConnect, message.fillOutAllMandatoryFields, false, 'failed', 'handleSaveAssociateData');
        }
    }



    //edit click on table
    const handleClickEdit = (data: any) => {
        const uuid = data.uuid;
        setUserId(uuid);
        const req: IAssociateState = {
            unid, token, uuid
        };
        dispatch(editAssociateData(req));
        setOpenCreateAssociateUser(true);
    }

    //View click on table
    const handleClickView = (data: any) => {
        setviewOnly(true);
        handleClickEdit(data);
    }

    //function handles Deactivate and Activation of client 
    const handleConfiramation = () => {
        const uuid = storeStatusData.uuid
        const req: IAssociateStatus = {
            unid, token, uuid, action: storeStatusData.active ? 1 : 2
        };
        dispatch(updateAssociateUserStatus(req));
        setShowStatusConfirmation(false);
    }

    //confirmation popup 
    const handleClickStatus = (data: any) => {
        setShowStatusConfirmation(true);
        setStoreStatusData(data);
        setStoreStatus(data.active)
    }

    //for reset all value in modal
    const handleAssociateReset = () => {
        setFirstName('');
        setMiddleName('');
        setLastName('');
        setGender('');
        setFacility([]);
        setNotes('');
        setEmailNotification('2');
        setEmail('');
        setresendEmail(false);
        setMobile('');
        setAlternativeMobile('');
        setProfileImage("");
        setOpenCreateAssociateUser(!openCreateAssociateUser);
        setviewOnly(false);
        setAddAssociate(true);
        setUserId('');
    }


    //for add profile image
    const handleAddProImage = (selectedFile: File) => {
        const req: IImageInput = {
            token, unid, image: selectedFile, type: LOGO_UPLOAD_OTHERS, image_type: LOGO_UPLOAD_PROFILE_PIC
        }
        dispatch(addAssociateImageInput(req));
    }
    //remove profile image
    const handleRemoveProImage = () => {
        if (imageProId && imageProId !== null) {
            const req: IRemoveImage = {
                token, unid, fid: imageProId
            }
            dispatch(removeAssociateImageInput(req));
        }
    }

    //Resent email 
    const onResendEmail = (data: any) => {
        const req: IResentEmail = {
            unid, token, user_id: data.user_id, user_email: data.email

        };
        dispatch(resentVerificationAssociateMail(req));
    }

    
    //Filter and sorting
    const handleAssociateList = (value: any, field: string) => {
        const req: IAssociateState = {
            unid, token, facility: navFacility, client: client, user: logedUsername,
             group: loginUserRole, user_type: CLIENT_ASSOCIATE,             
            page: (field === 'pagination') ? value : currentPage,
            sort: (field === 'sort_associate') ? value : sortField,
        };
        dispatch(fetchAssociateList(req));
    }
    
    const handlePageChange = (page: number) => {
        setCurrentPage(page);

        if (checkViewPermission) {
            handleAssociateList(page, 'pagination');
            // const req: IAssociateState = {
            //     unid, token, facility: navFacility, client: client, user: logedUsername, group: loginUserRole, user_type: CLIENT_ASSOCIATE, page: page
            // };
            // dispatch(fetchAssociateList(req));
        }
    };

    return (
        <div className='w-full'>
            <div className="flex justify-between items-center  pt-4 pb-[45px] pl-12 pr-8">
                <div>
                    <h1 className='text-2xl text-[var(--color-212121)] '>Associate Users</h1>
                </div>
                <div>
                    {useHasActionPermission(ROLE_CLIENT_ASSOCIATE_USER, P_ACT_ADD_EDIT) && <ButtonType type='iconLeft' buttonLabel='Add Associate User' icon={plusIcon} onClick={() => handleAssociateReset()} />}
                </div>
            </div>
            <div className='pl-12 pr-8 '>
                <Table
                    parentPage={ROLE_CLIENT_ASSOCIATE_USER}
                    setPermission={true}
                    titles={associateUserListHeader}
                    data={associateUserListData}
                    currentPage={currentPage}
                    hasResendEmailPermission={hasResendEmailPermission}
                    numRowsPerPage={itemsPerPage}
                    sortedFieldName={sortField}
                    handleSort={(sortField: string) => {
                        setSortField(sortField);
                        handleAssociateList(sortField, 'sort_associate');
                    }}
                    onClickEdit={(uuid: any) => handleClickEdit(uuid)}
                    onClickView={(uuid: any) => handleClickView(uuid)}
                    onClickStatus={(obj: any) => handleClickStatus(obj)}
                    onResendEmail={(obj: any) => onResendEmail(obj)}
                    className={Math.ceil(paginationTotalCount / itemsPerPage) > 1 ? '' : ' mb-28 '}

                />
            </div>

            <Paginations
                totalItems={paginationTotalCount}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                setCurrentPage={handlePageChange}
            />

            <Modal
                open={openCreateAssociateUser}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className='focus:outline-none w-full max-w-[936px]'>
                    <ModalHeader title={addAssociate ? 'Add Associate User' : viewOnly ? 'Associate User' : 'Update Associate User'} onClick={() => handleAssociateReset()} />

                    <form className='max-h-[calc(100vh-200px)] custom-scrollBar' onSubmit={e => handleSaveAssociateData(e)}>
                        <div className="px-9">

                            <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                                <div className="flex flex-col gap-9 w-full">
                                    <TextField
                                        label=' First Name'
                                        required={true}
                                        value={firstName}
                                        disabled={viewOnly}
                                        onChange={(e) => setFirstName(e.target.value)}
                                        placeholder=' First Name' />
                                    <TextField
                                        label=' Middle Name'
                                        value={middleName}
                                        disabled={viewOnly}
                                        onChange={(e) => setMiddleName(e.target.value)}
                                        placeholder=' Middle Name' />
                                </div>
                                <ImageInput label='Profile Picture' profileImage={profileImage} handleAddImage={handleAddProImage} handleRemoveImage={handleRemoveProImage} />

                            </div>

                            <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                                <TextField
                                    label=' Last Name'
                                    required={true}
                                    value={lastName}
                                    disabled={viewOnly}
                                    onChange={(e) => setLastName(e.target.value)}
                                    placeholder=' Last Name' />
                                <SelectField
                                    label='Select Gender'
                                    placeHolder={true}
                                    value={gender}
                                    disabled={viewOnly}
                                    onChange={(e) => setGender(e.target.value)}
                                    options={[{ name: 'Select Gender', id: '' }, { name: 'Male', id: '1' }, { name: 'Female', id: '2' }, { name: 'Other', id: '3' }]}
                                />
                            </div>

                            <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                                <MultiSelectField
                                    onChange={(values) => setFacility(values)}
                                    value={facility}
                                    disabled={viewOnly}
                                    placeHolder={'Select Facility'}
                                    required={true}
                                    label='Select Facility'
                                    selectAllLabel={{ name: 'All Facility', id: 'All', isChecked: false }}
                                    options={facilityData}
                                />
                                <div className="w-full flex flex-col gap-1">

                                    <TextField
                                        type='email'
                                        label='Email'
                                        value={email}
                                        disabled={viewOnly}
                                        onChange={e => setEmail(e.target.value)}
                                        required={true}
                                        placeholder='Email' />
                                    {(!addAssociate && !resendEmail && hasResendEmailPermission) && <div className='w-full text-right'>
                                        <sup
                                            className="cursor-pointer text-[var(--color-0048D4)] hover:underline"
                                            onClick={() => onResendEmail({ user_id: userEmailId, email: email })}
                                        >
                                            Resend email
                                        </sup>
                                    </div>}
                                </div>
                            </div>

                            <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">

                                <PhoneInputFeild
                                    inputProps={{
                                        name: `mobile`,
                                        autoFocus: false,
                                        disabled: viewOnly,
                                    }}
                                    value={mobile}
                                    label='Mobile'
                                    defaultCountry={DEFAULT_COUNTRY_US}
                                    placeholder='Mobile Number'
                                    onChange={phone => setMobile(phone)} />
                                <div className="w-full"></div>


                            </div>
                            <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                                <label className='flex flex-col gap-1 w-full'>

                                    <span className=' text-[15px] text-[var(--color-212121)] tracking-[0.05px]'>
                                        Notes
                                    </span>
                                    <textarea value={notes}
                                        disabled={viewOnly} onChange={e => setNotes(e.target.value)} name="" id="" cols={30} rows={6} className='common-input focus:outline-none   resize-none' />
                                </label>
                                <div className='w-full'></div>
                            </div>
                            <div className="flex gap-8 w-full my-9 flex-wrap md:flex-nowrap">
                                <div className="w-full">
                                    <div className="flex items-center justify-between mb-2">
                                        <span className=' text-[15px] text-[var(--color-212121)] tracking-[0.05px] w-[141px]'>
                                            Email Notifications
                                        </span>
                                        <div className="flex gap-4">
                                            <input type="radio" id="Yes"
                                                disabled={viewOnly} name="email_notification" value="1" checked={emailNotification === '1'} onClick={() => setEmailNotification('1')} /><label htmlFor="Yes">Yes</label></div>
                                        <div className="flex gap-4">
                                            <input type="radio" id="No"
                                                disabled={viewOnly} name="email_notification" value="2" checked={emailNotification === '2'} onClick={() => setEmailNotification('2')} /><label htmlFor="No">No</label></div>

                                    </div>
                                    {/* #### Later Use #### */}
                                    {/* <div className="flex items-center justify-between">
                                        <span className=' text-[15px] text-[var(--color-212121)] tracking-[0.05px] w-[141px]'>
                                            SMS Notifications
                                        </span>
                                        <div className="flex gap-4">
                                            <input type="radio" id="Yes" name="sms_notification" value="Yes" /><label htmlFor="Yes">Yes</label></div>
                                        <div className="flex gap-4">
                                            <input type="radio" id="No" name="sms_notification" value="No" /><label htmlFor="No">No</label></div>

                                    </div> */}

                                </div>
                                <div className="w-full"> &nbsp;</div>

                            </div>
                            {!viewOnly && <>
                                <hr className='border-[var(--color-E0E0E0)] ' />
                                <div className="flex gap-4 justify-end items-end my-9">
                                    <ButtonType
                                        type='webCardbtn'
                                        buttonLabel='Cancel'
                                        onClick={() => handleAssociateReset()}
                                        className='px-12 !important bg-[var(--color-white)] !important imp-text-424242 hover:bg-[var(--color-0000000a)]' />
                                    <ButtonType
                                        type='webCardbtn'
                                        buttonLabel='Save'
                                        buttonType='submit'
                                        className='px-12 bg-[var(--color-0048D4)]' />
                                </div></>}
                        </div>
                    </form>
                </Box>
            </Modal>

            <ConfirmationMessage
                title={message.CFPCareConnect}
                description={`Are you sure you want to ${storeStatus ? 'deactivate' : 'activate'} this Associate User?`}
                open={showStatusConfirmation}
                textType={false}
                type={storeStatus ? 'danger' : 'success'}
                buttonTwoOnClick={() => setShowStatusConfirmation(false)}
                buttonOneOnClick={() => handleConfiramation()}
                buttonLabelOne={storeStatus ? 'Deactivate' : 'Activate'}
                buttonLabelTwo='Cancel'
            />
        </div>
    )
}

export default AssociateUserList