import { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './App.css';
import WebHomePage from "./webPages";
import PatientRegistration from "./pages/patientRegistration";
import NursingStaffRegistration from "./pages/nursingStaffRegistration";
import FamilyMemberRegistration from "./pages/familyMemberRegistration";
import { PRIVACY_POLICY, ROUTE_404, ROUTE_ASSOCIATE_USERLIST, ROUTE_CLIENT_LIST, ROUTE_DASHBOARD, ROUTE_FACILITY_LIST, ROUTE_FAMILY_REG, ROUTE_LOGIN, ROUTE_LOGIN_PATIENT, ROUTE_NOTIFICATION_LIST, ROUTE_NURSING_STAFF_LIST, ROUTE_NURSING_STAFF_REG, ROUTE_PATIENT_LIST, ROUTE_PATIENT_REG, ROUTE_PROFILE, ROUTE_PROVIDER_LIST, ROUTE_RESET_PASSWORD, ROUTE_ROLE_PERMISSION, ROUTE_TIMESHEET } from "./config/routeConfig";
import LoginPage from "./pages/loginPage";
import Dashboard from "./pages/dashboard";
import ProtectedRoutes from "./utilities/ProtectedRoutes";
import NursingStaffList from "./pages/nursingStaffList";
import ClientList from "./pages/clientList";
import FacilityList from "./pages/facilityList";
import ProviderList from "./pages/providerList";
import PatientList from "./pages/patientList";
import AssociateUserList from "./pages/associateUserList";
import TimeSheet from "./pages/timeSheet";
import NotificationList from "./pages/notificationList";
import RolesAndPermission from "./pages/rolesAndPermission";
import UrlNotFound from "./pages/404";
import 'react-notifications-component/dist/theme.css'
import { ReactNotifications } from "react-notifications-component";
import { SetLoader } from "./components/loader/SetLoader";
import ResetPassword from "./pages/resetPassword";
import { setIntercept } from "./services/apiService";
import { useAppDispatch, useAppSelector } from "./helpers/hook";
import { changeToken, loginPageSlices } from "./pages/loginPage/loginPageSlice";
import MyProfile from "./pages/myProfile";
import PatientLogin from "./pages/loginPage/PatientLogin";
import PrivacyPolicy from "./webPages/PrivacyPolicy";
import { handleLogoutData } from "./services/loginService";
import { getRefreshToken } from "./config/permissions";

function App() {
  const dispatch = useAppDispatch();
  // const { refreshToken, loginUserRole } = useAppSelector(loginPageSlices);
  const {  unid, uuid, loginUserRole, firebaseToke } = useAppSelector(loginPageSlices);
  const refreshToken = getRefreshToken();


  const [newToken, setNewToken] = useState<string>('');

  useEffect(() => {
    if (refreshToken !== '') {
      setIntercept( loginUserRole, (token) => setNewToken(token));
    }
  });

  useEffect(() => {
    if (newToken !== '') {
      dispatch(changeToken(newToken));
    }
    if (newToken === '400' && location.pathname !== ROUTE_RESET_PASSWORD) {
      dispatch(handleLogoutData({ unid, uuid, firebase_token: firebaseToke, device_type: 1 }));
    }
  }, [newToken]);



  return (
    <div className="App">
      <Router  >
        <ReactNotifications />
        <SetLoader />
        <Routes>
          <Route path="/" element={<WebHomePage />} />
          <Route path={PRIVACY_POLICY} element={<PrivacyPolicy />} />
          <Route path={ROUTE_LOGIN} element={<LoginPage />} />
          <Route path={ROUTE_LOGIN_PATIENT} element={<PatientLogin />} />
          <Route path={ROUTE_RESET_PASSWORD} element={<ResetPassword />} />
          <Route path={ROUTE_PATIENT_REG} element={<PatientRegistration />} />
          <Route path={ROUTE_NURSING_STAFF_REG} element={<NursingStaffRegistration />} />
          <Route path={ROUTE_FAMILY_REG} element={<FamilyMemberRegistration />} />
          <Route path={ROUTE_404} element={<UrlNotFound />} />

          <Route element={<ProtectedRoutes />}>
            <Route path={ROUTE_DASHBOARD} element={<Dashboard />} />
            <Route path={ROUTE_NURSING_STAFF_LIST} element={<NursingStaffList />} />
            <Route path={ROUTE_CLIENT_LIST} caseSensitive={true} element={<ClientList />} />
            <Route path={ROUTE_FACILITY_LIST} element={<FacilityList />} />
            <Route path={ROUTE_PROVIDER_LIST} element={<ProviderList />} />
            <Route path={ROUTE_PATIENT_LIST} element={<PatientList />} />
            <Route path={ROUTE_ASSOCIATE_USERLIST} element={<AssociateUserList />} />
            <Route path={ROUTE_TIMESHEET} element={<TimeSheet />} />
            <Route path={ROUTE_NOTIFICATION_LIST} element={<NotificationList />} />
            <Route path={ROUTE_ROLE_PERMISSION} element={<RolesAndPermission />} />
            <Route path={ROUTE_PROFILE} element={<MyProfile />} />
          </Route>

        </Routes>
      </Router>
    </div>
  );
}

export default App;
