import React, { useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import ButtonType from '../button/ButtonType';
import regSuccessImg from '../../assets/images/regSuccessImg.svg'
import { NURSING_STAFF } from '../../config/permissions';

interface RegistrationSuccessProps {
  type?: 'registrationSuccess' | 'Custom';
  onClickOne?: () => void;
  buttonLabelOne: string;
  open: boolean;
  parent: string;
  onClose: () => void;
}

const RegistrationSuccess: React.FC<RegistrationSuccessProps> = (props) => {
  const cancelButtonRef = useRef(null);

  return <Transition.Root show={props.open} as={React.Fragment}>
    <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={props.onClose}>
      {/* Rest of the dialog code */}
      <Transition.Child
        as={React.Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" onClick={() => props.onClose} />
      </Transition.Child>

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8  p-9">
              <div className="flex flex-col justify-center items-center ">
                <img src={regSuccessImg} alt="" loading='lazy' decoding='async' />
                <div className="mt-3 text-center max-w-[320px]">
                  <Dialog.Title as="h3" className="text-xl font-medium tracking-[0.05px] text-[var(--color-212121)] mt-9 mb-6">
                    Thank you for registration with CFP Connect
                  </Dialog.Title>
                  {props.parent === NURSING_STAFF && <div >
                    <p className="text-sm  tracking-[0.05px] text-[var(--color-9E9E9E)]">An email has been sent to your email id. Make sure you validate the email by clicking the link sent with in 24 hours</p>
                  </div>}
                </div>
              </div>

              <div className=" grid place-items-center pt-9">

                <ButtonType
                  type='webCardbtn'
                  buttonLabel={props.buttonLabelOne}
                  onClick={props.onClickOne}
                  className='px-12 !important' />
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>

    </Dialog>
  </Transition.Root>;
};

export default RegistrationSuccess;
