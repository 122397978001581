import React, { useEffect } from 'react'
import { PRIVACY_POLICY } from '../../config/routeConfig'
import { useAppDispatch, useAppSelector } from '../../helpers/hook';
import { CommonSlices } from '../../pages/common/commonSlice';
import { fetchWebVersion } from '../../services/commonServices';

const Footer = () => {
    const dispatch = useAppDispatch();
    const { version } = useAppSelector(CommonSlices);

    useEffect(() => {
        dispatch(fetchWebVersion());
    }, []);
    return (
        <footer className='bg-transparent text-[var(--color-212121)] border-t border-[var(--color-e9e3e3)] text-[15px]  flex items-center justify-center w-full px-8 py-4'>
            <div className='pr-6 text-xs  border-r border-[var(--color-BDBDBD)]'>© 2024 CFP Care Connect. All rights reserved.
             Ver {version}
             </div>
            <div className='pl-6 text-xs hover:underline hover:text-[var(--color-0048D4)]'><a href={PRIVACY_POLICY}>Privacy Policy</a></div>
        </footer>
    )
}

export default React.memo(Footer);   