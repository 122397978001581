import { createAsyncThunk } from "@reduxjs/toolkit";
import { IDashboardState, IMarkAsComplete, IMessageList, IPatientMessageHistory, IPatientSearchByName, IPlaceOrder, ISentMessage, ISingleMessage } from "../models/dashboardInterfaces";
import { URL_ADD_MESSAGE, URL_DASHBOARD_INDIV, URL_MARK_COMPLETED, URL_MESS_COMPLETED, URL_MESS_HISTORY_PATIENT, URL_MESS_INBOX,  URL_MESS_SENT, URL_MESS_SINGLE, URL_PATIENT_SEARCH, URL_PLACE_ORDER } from "../config/apiUrls";
import { post } from "./apiService";

export const fetchInboxMessage = createAsyncThunk(
    'dashboard/listInboxMessage',
    async (form: IMessageList, thunkAPI) => {
        try {
            const { page, ...body } = form;
            let url = URL_MESS_INBOX;
            if (page !== 0) {
                url = url + '?page=' + page
            }
            const response = post(url, body);
            return (await response).data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const fetchInboxNewMessage = createAsyncThunk(
    'dashboard/listInboxNewMessage',
    async (form: IMessageList, thunkAPI) => {
        try {
            const { page, ...body } = form;
            let url = URL_MESS_INBOX;
            if (page !== 0) {
                url = url + '?page=' + page
            }
            const response = post(url, body);
            return (await response).data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const fetchCompletedMessage = createAsyncThunk(
    'dashboard/listCompletedMessage',
    async (form: IMessageList, thunkAPI) => {
        try {
            const { page, ...body } = form;
            let url = URL_MESS_COMPLETED;
            if (page !== 0) {
                url = url + '?page=' + page
            }
            const response = post(url, body);
            return (await response).data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
)

export const fetchSendMessage = createAsyncThunk(
    'dashboard/listSendMessage',
    async (form: IMessageList, thunkAPI) => {
        try {
            const { page,  ...body } = form;
            let url = URL_MESS_SENT;
            if (page !== 0) {
                url = url + '?page=' + page
            }
            const response = post(url, body);
            return (await response).data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
)

export const fetchSendDashboardMessage = createAsyncThunk(
    'dashboard/fetchSendDashboardMessage',
    async (form: IMessageList, thunkAPI) => {
        try {
            const { page,  ...body } = form;
            let url = URL_MESS_SENT;
            if (page !== 0) {
                url = url + '?page=' + page
            }
            const response = post(url, body);
            return (await response).data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
)

export const handleMarkAsComplete = createAsyncThunk(
    'dashboard/handleMarkAsComplete',
    async (form: IMarkAsComplete, thunkAPI) => {
        try {
            const response = post(URL_MARK_COMPLETED, form);
            return (await response).data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
)

export const handleSingleMessage = createAsyncThunk(
    'dashboard/handleSingleMessage',
    async (form: ISingleMessage, thunkAPI) => {
        try {
            const { page, ...body } = form;
            let url = URL_MESS_SINGLE;
            if (page !== 0) {
                url = url + '?page=' + page
            }
            const response = post(url, body);
            return (await response).data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
)


export const fetchindividualList = createAsyncThunk(
    'dashboard/fetchindividualList',
    async (form: IDashboardState, thunkAPI) => {
        try {
            const response = post(URL_DASHBOARD_INDIV, form);
            return (await response).data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
)

export const onHandleSentMessage = createAsyncThunk(
    'dashboard/onHandleSentMessage',
    async (form: ISentMessage, thunkAPI) => {
        try {
            const response = post(URL_ADD_MESSAGE, form);
            return (await response).data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
)

export const setPlaceOrderContent = createAsyncThunk(
    'dashboard/setPlaceOrderContent',
    async (form: IPlaceOrder, thunkAPI) => {
        try {
            const response = post(URL_PLACE_ORDER, form);
            return (await response).data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
)

export const patientSearchByName = createAsyncThunk(
    'dashboard/patientSearchByName',
    async (form: IPatientSearchByName, thunkAPI) => {
        try {
            const response = post(URL_PATIENT_SEARCH, form);
            return (await response).data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
)

export const fetchPatientMessageHistory = createAsyncThunk(
    'dashboard/fetchPatientMessageHistory',
    async (form: IPatientMessageHistory, thunkAPI) => {
        try {
            const { page, ...body } = form;
            let url = URL_MESS_HISTORY_PATIENT;
            if (page !== 0) {
                url = url + '?page=' + page
            }
            const response = post(url, body);
            return (await response).data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
)

