import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IAllMessages, IAllSentMessages, IMessageTo, IPatientSearchList, IPlaceOrderState, ISelectPreviousMsg, ISelectedSingleMsgUser } from "../../models/dashboardInterfaces";
import { fetchCompletedMessage, fetchInboxMessage, fetchSendMessage, fetchindividualList, handleMarkAsComplete, handleSingleMessage, onHandleSentMessage, setPlaceOrderContent, patientSearchByName, fetchInboxNewMessage, fetchPatientMessageHistory, fetchSendDashboardMessage } from "../../services/dashboardServices";
import { RootState } from "../../store";
import { setNotification } from "../../components/notification/ToastNotification";
import { message } from "../../utilities/locals/messages";
import { ATLEAST_ONE_PATIENT, ATMOST_ONE_PATIENT, CLIENT_ADMIN_ASSOCIATE, INVALID_EMAIL_ID, INVALID_FACILITY, LBL_ASSOCIATES, LBL_GENERAL, LBL_GENERAL_PATIENTS, LBL_NURSING_STAFF, LBL_PATIENTS, LBL_PROVIDERS, MSG_ACTION_COMPLETE, MSG_GENERAL, MSG_NURSING_STAFF, MSG_PROVIDER, MSG_TAB_NO_MESSAGE, PATIENTS, PATIENTS_RELATIVES, SML_ASSOCIATES } from "../../config/permissions";

export interface DashboardState {
    loading: 'idle' | 'loading' | 'success' | 'fetchSuccessfully' | 'messageSented' | 'fetchIndividualSuccessfully' | 'fetchMessagefully' | 'FetchSingleDataSuccessfull' | 'markedSuccess' | 'error' | 'patientSearchSuccessful' | 'patientMsgHistorySuccessfull';
    messageInboxData: IAllMessages[];
    messageCompletedData: IAllMessages[];
    messageNewData: IAllMessages[];
    toMessageList: IMessageTo[];
    selectedMessageTab: 0 | 1 | 2 | 3;
    sentMessageData: IAllSentMessages[];
    selectedSingleMsgUser: ISelectedSingleMsgUser
    selectPreviousMsg: ISelectPreviousMsg[];
    paginationTotalCount: number;
    paginationInboxCount: number;
    placeOrderContent: IPlaceOrderState;
    resetMessage: boolean;
    patientListClickedStatus: boolean;
    patientSearchList: IPatientSearchList[];
    patientListClickedData: IPatientSearchList;
    sidebarSearch: string;
    sidebarfilterOption: string;
}

const initialState: DashboardState = {
    loading: 'idle',
    messageInboxData: [],
    messageCompletedData: [],
    messageNewData: [],
    toMessageList: [],
    selectedMessageTab: MSG_TAB_NO_MESSAGE,
    sentMessageData: [],
    selectedSingleMsgUser: {
        name: '',
        facility_name: '',
        facility: 0,
        user_id: 0,
        email: '',
        group_name: '',
        profile_pic: '',
        allergy: '',
        type: 0,
        priority: 0,
        message_id: 0,
        dob: '',
        message_result: [],
        sender_info: [],
        sender_id: '',
    },
    paginationTotalCount: 0,
    paginationInboxCount: 0,
    selectPreviousMsg: [],
    placeOrderContent: {
        zip_code: '',
        street1: '',
        street2: '',
        city: '',
        state: '',
        country: '',
        name: '',
        logo_url: '',
    },
    resetMessage: false,
    patientListClickedStatus: false,
    patientSearchList: [],
    patientListClickedData: {
        id: 0,
        name: '',
        email: '',
        profile_pic: '',
        allergies: '',
        patient_dob: '',
        notes: '',
        patient_gender: 0,
        relationship: 0,
        facility_id: 0,
        facility_name: '',
        user_id: 0,
    },
    sidebarSearch: '',
    sidebarfilterOption: '0'
}

export const dashboardSlice = createSlice({
    name: 'dashboardSlice',
    initialState,
    reducers: {
        changeDashboardLoading: (state, action: PayloadAction<any>) => {
            state.loading = action.payload;
        },
        changeMessageTab: (state, action: PayloadAction<any>) => {
            state.selectedMessageTab = action.payload;

        },
        setResetMessageValues: (state, action: PayloadAction<any>) => {
            state.resetMessage = action.payload;
        },
        resetPatientSearchList: (state, action: PayloadAction<any>) => {
            state.patientSearchList = action.payload;
        },
        setPatientListClickedStatus: (state, action: PayloadAction<any>) => {
            state.patientListClickedStatus = action.payload;
        },
        setPatientListClickedData: (state, action: PayloadAction<any>) => {
            state.patientListClickedData = action.payload as IPatientSearchList;
        },
        setSidebarSearch: (state, action: PayloadAction<any>) => {
            state.sidebarSearch = action.payload;
        },
        setSidebarfilterOption: (state, action: PayloadAction<any>) => {
            state.sidebarfilterOption = action.payload;
        },

    },
    extraReducers: (builder) => {

        builder.addCase(fetchInboxMessage.pending, (state) => {
            state.messageInboxData = [];
            state.paginationInboxCount = 0;
            state.loading = 'idle';
        });
        builder.addCase(fetchInboxMessage.fulfilled, (state, action) => {
            state.loading = 'fetchMessagefully';
            state.paginationInboxCount = action.payload.count;
            state.messageInboxData = action.payload.results as IAllMessages[];
        });
        builder.addCase(fetchInboxMessage.rejected, (state) => {
            state.loading = 'error';
        });

        builder.addCase(fetchInboxNewMessage.pending, (state) => {
            state.messageNewData = [];
            state.loading = 'idle';
        });
        builder.addCase(fetchInboxNewMessage.fulfilled, (state, action) => {
            state.loading = 'fetchMessagefully';
            state.messageNewData = action.payload.results as IAllMessages[]
        });
        builder.addCase(fetchInboxNewMessage.rejected, (state) => {
            state.loading = 'error';
        });

        builder.addCase(fetchCompletedMessage.pending, (state) => {
            state.messageCompletedData = [];
            state.paginationInboxCount = 0;
            state.loading = 'idle';
        });
        builder.addCase(fetchCompletedMessage.fulfilled, (state, action) => {
            state.loading = 'fetchMessagefully';
            state.paginationInboxCount = action.payload.count;
            state.messageCompletedData = action.payload.results as IAllMessages[];
        });
        builder.addCase(fetchCompletedMessage.rejected, (state) => {
            state.loading = 'error';
        });

        builder.addCase(fetchSendMessage.pending, (state) => {
            state.loading = 'idle';
            state.paginationTotalCount = 0;
            state.paginationInboxCount = 0;
        });

        builder.addCase(fetchSendMessage.fulfilled, (state, action) => {
            state.loading = 'fetchMessagefully';
            state.paginationTotalCount = action.payload.count;
            state.paginationInboxCount = action.payload.count;

            state.sentMessageData = action.payload.results as IAllSentMessages[];
        });
        builder.addCase(fetchSendMessage.rejected, (state) => {
            state.loading = 'error';
        });

        builder.addCase(fetchSendDashboardMessage.pending, (state) => {
            state.loading = 'loading';
            state.paginationTotalCount = 0;
            state.paginationInboxCount = 0;
            state.selectPreviousMsg = [];
        });
        builder.addCase(fetchSendDashboardMessage.fulfilled, (state, action) => {
            state.loading = 'fetchMessagefully';
            state.paginationTotalCount = action.payload.count;
            state.paginationInboxCount = action.payload.count;

            state.selectPreviousMsg = action.payload.results as ISelectPreviousMsg[];
        });
        builder.addCase(fetchSendDashboardMessage.rejected, (state) => {
            state.loading = 'error';
        });

        builder.addCase(handleMarkAsComplete.pending, (state) => {
            state.loading = 'loading';
        });
        builder.addCase(handleMarkAsComplete.fulfilled, (state, action) => {
            state.loading = 'markedSuccess';
            if (action.payload.message === MSG_ACTION_COMPLETE) {
                setNotification(message.CFPCareConnect, message.messageMarkAsCompleted, false, 'success', 'handleMarkAsComplete');
            } else {
                setNotification(message.CFPCareConnect, message.messageMarkAsIncompleted, false, 'success', 'handleMarkAsComplete');
            }

        });
        builder.addCase(handleMarkAsComplete.rejected, (state) => {
            state.loading = 'error';
            setNotification(message.CFPCareConnect, message.messageMarkAsCompletedFailed, false, 'failed', 'handleMarkAsComplete');
        });

        builder.addCase(handleSingleMessage.pending, (state) => {
            state.loading = 'loading';
            state.paginationTotalCount = 0;
        });
        builder.addCase(handleSingleMessage.fulfilled, (state, action) => {
            state.paginationTotalCount = action.payload.count;
            const list: { name: string, value: string, group_name?: string, user_id?: number }[] = action.payload.info.message_result;
            const toMessage: { label: string, value: string, group_name?: string, user_id?: number }[] = list.map(item => {
                if (item.name === MSG_PROVIDER && item.value === MSG_PROVIDER) {
                    return { label: LBL_PROVIDERS, value: MSG_PROVIDER }
                }
                if (item.name === MSG_NURSING_STAFF && item.value === MSG_NURSING_STAFF) {
                    return { label: LBL_NURSING_STAFF, value: MSG_NURSING_STAFF }
                }
                if (item.name === PATIENTS && item.value === PATIENTS) {
                    return { label: LBL_PATIENTS, value: PATIENTS_RELATIVES }
                }
                if (item.name === SML_ASSOCIATES && item.value === SML_ASSOCIATES) {
                    return { label: LBL_ASSOCIATES, value: CLIENT_ADMIN_ASSOCIATE }
                }
                if (item.name === MSG_GENERAL && item.value === MSG_GENERAL) {
                    return { label: LBL_GENERAL, value: MSG_GENERAL }
                }
                return { label: item.name, value: item.value, group_name: item.group_name, user_id: item.user_id }
            });


            state.selectedSingleMsgUser = {
                ...action.payload.info,
                message_result: toMessage,
            };

            state.selectPreviousMsg = action.payload.results as ISelectPreviousMsg[];
            state.loading = 'FetchSingleDataSuccessfull';
        });
        builder.addCase(handleSingleMessage.rejected, (state) => {
            state.loading = 'error';
        });


        builder.addCase(fetchPatientMessageHistory.pending, (state) => {
            state.loading = 'loading';
            state.paginationTotalCount = 0;
        });
        builder.addCase(fetchPatientMessageHistory.fulfilled, (state, action) => {
            state.selectPreviousMsg = action.payload.results as ISelectPreviousMsg[];
            state.loading = 'patientMsgHistorySuccessfull';
        });
        builder.addCase(fetchPatientMessageHistory.rejected, (state) => {
            state.loading = 'error';
        });



        builder.addCase(fetchindividualList.pending, (state) => {
            state.loading = 'loading';
        });
        builder.addCase(fetchindividualList.fulfilled, (state, action) => {
            state.loading = 'fetchIndividualSuccessfully';
            state.toMessageList = action.payload as IMessageTo[];
        });
        builder.addCase(fetchindividualList.rejected, (state) => {
            state.loading = 'error';
        });

        builder.addCase(onHandleSentMessage.pending, (state) => {
            state.resetMessage = false;
            state.loading = 'loading';
        });
        builder.addCase(onHandleSentMessage.fulfilled, (state) => {
            state.loading = 'messageSented';
            state.resetMessage = true;
            setNotification(message.CFPCareConnect, message.messageSentSuccessfully, false, 'success', 'onHandleSentMessage');
            state.selectedMessageTab = MSG_TAB_NO_MESSAGE;
        });
        builder.addCase(onHandleSentMessage.rejected, (state, { payload }: any) => {
            state.loading = 'error';

            if (payload.response.data.error_message === ATMOST_ONE_PATIENT) {
                setNotification(message.CFPCareConnect, message.atmostOnePatient, false, 'failed', 'onHandleSentMessage');
            } else if (payload.response.data.error_message === ATLEAST_ONE_PATIENT) {
                setNotification(message.CFPCareConnect, message.atleastOnePatient, false, 'failed', 'onHandleSentMessage');
            } else if (payload.response.data.error_message === INVALID_EMAIL_ID) {
                setNotification(message.CFPCareConnect, message.userHasNoEmail, false, 'failed', 'onHandleSentMessage');
            } else if (payload.response.data.error_message === INVALID_FACILITY) {
                setNotification(message.CFPCareConnect, message.invalidFacility, false, 'failed', 'onHandleSentMessage');
            } else {
                setNotification(message.CFPCareConnect, message.messageSentFailed, false, 'failed', 'onHandleSentMessage');
            }
        });

        builder.addCase(setPlaceOrderContent.pending, (state) => {
            state.loading = 'loading';
        });
        builder.addCase(setPlaceOrderContent.fulfilled, (state, action) => {
            state.placeOrderContent = action.payload as IPlaceOrderState;
            state.loading = 'fetchSuccessfully';
        });
        builder.addCase(setPlaceOrderContent.rejected, (state) => {
            state.loading = 'error';
        });

        // PATIENT SEARCH BY NAME 
        builder.addCase(patientSearchByName.pending, (state) => {
            state.loading = 'idle';
        });
        builder.addCase(patientSearchByName.fulfilled, (state, action) => {
            const general_patient = {
                id: 0,
                name: LBL_GENERAL_PATIENTS,
                email: '',
                user_id: 0,
                profile_pic: '',
                allergies: '',
                patient_dob: '',
                notes: '',
                patient_gender: 0,
                relationship: 0,
                facility_id: 0,
                facility_name: '',
            } as IPatientSearchList

            state.patientSearchList = [...action.payload, general_patient] as IPatientSearchList[];

            state.loading = 'patientSearchSuccessful';
        });
        builder.addCase(patientSearchByName.rejected, (state) => {
            state.loading = 'error';
        });

    }
})

export const { changeDashboardLoading, setSidebarSearch, setSidebarfilterOption, changeMessageTab, setResetMessageValues, setPatientListClickedStatus, resetPatientSearchList, setPatientListClickedData } = dashboardSlice.actions;
export const DashboardSlices = (state: RootState) => state.dashboardData;
export const dashboardReducer = dashboardSlice.reducer;