import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { setNotification } from "../../components/notification/ToastNotification";
import { authenticatePatientUser, authenticateUser, handleLogoutData, patientLoginEmailCheck, resetUserPasswordNoAuth } from "../../services/loginService";
import { message } from "../../utilities/locals/messages";
import { INVALID_USER, USER_NOT_APPROVED } from "../../config/permissions";


export interface ILoginState {
    loading: 'idle' | 'success' | 'loginSuccess' | 'logoutLoading' | 'logOutSuccess' | 'logOutError' | 'OTPsuccess' | 'loading' | 'error';
    storedPassword: string;
    storedUsername: string;
    logedUsername: string;
    logedUserID: number;
    logedUserProfilePic: string;
    storedrememberLogin: boolean;
    loginUserRole: number;
    loginUserName: string;
    userPermissions: { [role: string]: number[] };
    token: string;
    // refreshToken: string;
    unid: string;
    uuid: string;
    client: number;
    clientName: string;
    generateFirebaseToken: boolean;
    firebaseToke: string;
}

const initialState: ILoginState = {
    loading: 'idle',
    storedPassword: '',
    storedUsername: '',
    logedUsername: '',
    logedUserProfilePic: '',
    loginUserName: '',
    storedrememberLogin: false,
    generateFirebaseToken: false,
    loginUserRole: 0,
    logedUserID: 0,
    userPermissions: {},
    token: '',
    // refreshToken: '',
    unid: '',
    uuid: '',
    clientName: '',
    client: 0,
    firebaseToke: "",

}

export const loginPageSlice = createSlice({
    name: 'loginPageSlice',
    initialState,
    reducers: {
        storeCredentials: (state, action: PayloadAction<any>) => {
            state.storedPassword = action.payload.password;
            state.storedUsername = action.payload.userName;
            state.storedrememberLogin = action.payload.rememberLogin;
        },
        changeToken: (state, action: PayloadAction<any>) => {
            state.token = action.payload;
        },
        saveFirebaseToken: (state, action: PayloadAction<any>) => {
            state.firebaseToke = action.payload;
        },
        changeGenerateFirebaseToken: (state, action: PayloadAction<any>) => {
            state.generateFirebaseToken = action.payload;
        },
        changeLogedUserProfilePic: (state, action: PayloadAction<any>) => {
            state.logedUserProfilePic = action.payload;
        },
        changeLogedUserName: (state, action: PayloadAction<any>) => {
            state.loginUserName = action.payload;
        },
    },
    extraReducers: (builder) => {

        builder.addCase(authenticateUser.pending, (state) => {
            state.loading = 'loading';
            state.loginUserRole = 0;
            state.generateFirebaseToken = false;
        });
        builder.addCase(authenticateUser.fulfilled, (state, action) => {
            state.loading = 'loginSuccess';
            state.loginUserRole = action.payload.data.group_id;
            state.logedUserID = action.payload.data.user_id;
            state.loginUserName = action.payload.data.name;
            state.userPermissions = action.payload.data.role_permissions;
            state.uuid = action.payload.data.uuid;
            state.client = action.payload.data.client;
            state.clientName = action.payload.data.client_name;
            state.token = action.payload.tokens.access;
            // state.refreshToken = action.payload.tokens.refresh;
            state.unid = action.payload.unid;
            state.logedUsername = action.payload.data.username;
            state.logedUserProfilePic = action.payload.data.profile_pic;
            state.generateFirebaseToken = true;
            localStorage.setItem('refreshToken', action.payload.tokens.refresh);
        });
        builder.addCase(authenticateUser.rejected, (state, { payload }: any) => {
            state.loading = 'error';
            if (payload.response?.data?.message === USER_NOT_APPROVED) {
                setNotification(message.CFPCareConnect, message.userNotApproved, false, 'failed', 'authenticateUserfailed');
            } else if (payload.response?.data?.message === INVALID_USER) {
                setNotification(message.CFPCareConnect, message.userNotActivate, false, 'failed', 'authenticateUserfailed');
            } else {
                setNotification(message.CFPCareConnect, message.InvalidCredentials, false, "failed", "authenticateUserfailed");
            }
        });

        builder.addCase(authenticatePatientUser.pending, (state) => {
            state.loading = 'loading';
            state.loginUserRole = 0;
            state.generateFirebaseToken = false;
        });
        builder.addCase(authenticatePatientUser.fulfilled, (state, action) => {
            state.loading = 'loginSuccess';
            state.loginUserRole = action.payload.data.group_id;
            state.logedUserID = action.payload.data.user_id;
            state.loginUserName = action.payload.data.name;
            state.userPermissions = action.payload.data.role_permissions;
            state.uuid = action.payload.data.uuid;
            state.client = action.payload.data.client;
            state.clientName = action.payload.data.client_name;
            state.token = action.payload.tokens.access;
            // state.refreshToken = action.payload.tokens.refresh;
            state.unid = action.payload.unid;
            state.logedUsername = action.payload.data.username;
            state.logedUserProfilePic = action.payload.data.profile_pic;
            state.generateFirebaseToken = true;
            
            localStorage.setItem('refreshToken', action.payload.tokens.refresh);
        });
        builder.addCase(authenticatePatientUser.rejected, (state, { payload }: any) => {
            state.loading = 'error';
            if (payload.response?.data?.message === USER_NOT_APPROVED) {
                setNotification(message.CFPCareConnect, message.userNotApproved, false, 'failed', 'authenticateUserfailed');
            } else if (payload.response?.data?.message === INVALID_USER) {
                setNotification(message.CFPCareConnect, message.userNotActivate, false, 'failed', 'authenticateUserfailed');
            } else {
                setNotification(message.CFPCareConnect, message.invalidUser, false, "failed", "authenticateUserfailed");
            }
        });

        builder.addCase(handleLogoutData.pending, (state) => {
            state.loading = 'logoutLoading';
        });
        builder.addCase(handleLogoutData.fulfilled, (state) => {
            state.loading = 'logOutSuccess';
            state.token = '';
            // state.refreshToken = '';
            state.unid = '';
            state.uuid = '';
            state.logedUsername = '';
            state.logedUserID = 0;
            state.logedUserProfilePic = '';
            state.loginUserName = '';
            state.userPermissions = {};
            state.generateFirebaseToken = false;
            state.firebaseToke = "";
            
            localStorage.removeItem('refreshToken');
        });
        builder.addCase(handleLogoutData.rejected, (state) => {
            state.loading = 'logOutError';
            setNotification(message.CFPCareConnect, message.InvalidCredentials, false, "failed", "authenticateUserfailed");
        });

        builder.addCase(resetUserPasswordNoAuth.pending, (state) => {
            state.loading = 'loading';
        });
        builder.addCase(resetUserPasswordNoAuth.fulfilled, (state) => {
            state.loading = 'success';
            setNotification(message.CFPCareConnect, message.msgEmailResentSuccess, false, "success", "resetUserPasswordNoAuth");

        });
        builder.addCase(resetUserPasswordNoAuth.rejected, (state) => {
            state.loading = 'error'
            setNotification(message.CFPCareConnect, message.invalidUser, false, "failed", "resetUserPasswordNoAuth");

        });

        builder.addCase(patientLoginEmailCheck.pending, (state) => {
            state.loading = 'loading';
        });
        builder.addCase(patientLoginEmailCheck.fulfilled, (state) => {
            state.loading = 'OTPsuccess';
            setNotification(message.CFPCareConnect, message.msgEmailOTP, false, "success", "patientLoginEmailCheck");

        });
        builder.addCase(patientLoginEmailCheck.rejected, (state, { payload }: any) => {
            state.loading = 'error'

            if (payload.response.data.error_message === USER_NOT_APPROVED) {
                setNotification(message.CFPCareConnect, message.userNotApproved, false, 'failed', 'patientLoginEmailCheck');
            } else if (payload.response.data.error_message === INVALID_USER) {
                setNotification(message.CFPCareConnect, message.userNotActivate, false, 'failed', 'authenticateUserfailed');
            } else {
                setNotification(message.CFPCareConnect, message.invalidUser, false, "failed", "patientLoginEmailCheck");
            }

        });
    }
});

export const { storeCredentials, changeGenerateFirebaseToken, changeToken, changeLogedUserProfilePic, saveFirebaseToken, changeLogedUserName } = loginPageSlice.actions;
export const loginPageSlices = (state: RootState) => state.loginPageData;
export const loginPageReducer = loginPageSlice.reducer;